import i18next from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import jqueryI18next from "jquery-i18next";

//const getCurrentLng = () => i18next.language || window.localStorage.i18nextLng || '';
//console.log(i18next.language, window.localStorage.i18nextLng, getCurrentLng);

i18next
    .use(Backend)
    .use(LanguageDetector)
    .init(
        {
            partialBundledLanguages: true,
            fallbackLng: "en",
            ns: [],
            defaultNS: "translation",
            fallbackNS: "main-translation",
            backend: {
                loadPath: "/assets/jsn/locales/{{lng}}/{{ns}}.json",
            },
            detection: {
                order: [
                    "querystring",
                    "cookie",
                    "localStorage",
                    "navigator",
                    "htmlTag",
                    "path",
                    "subdomain",
                ],
                lookupQuerystring: "lng",
                lookupCookie: "i18next",
                lookupLocalStorage: "i18nextLng",
                lookupFromPathIndex: 0,
                lookupFromSubdomainIndex: 0,
                caches: ["localStorage", "cookie"],
                excludeCacheFor: ["cimode"],
                cookieMinutes: 10,
                cookieDomain: "produck.de",
                htmlTag: document.documentElement,
            },
            returnObjects: true,
            useDataAttrOptions: true,
            debug: true,
            resources: {
                de: {
                    translation: {
                        "articlesection": {
                          "accepted_by": "Akzeptiert von Nutzer ",
                          "add_block": "Block hinzufügen",
                          "add_criterion_label": "+ Kriterium",
                          "add_template_label": "+ Vorlage",
                          "article_outsourced": "Artikel extern freigegeben",
                          "article_published": "Artikel veröffentlicht",
                          "article_type_label": "Typ",
                          "article_unpublished": "Artikel unveröffentlicht",
                          "author_label": "Autor:",
                          "choose_target_site_for": "<span class=\"flex-box w100 fs-12\">Wähle deine Zielseite für die Veröffentlichung von: </span><span class=\"flex-box w100 fs-14\"><strong>\"{{title}}\"</strong></span>",
                          "create": "Entwurf erstellen",
                          "create_article_label": "<i class=\"material-icons\">playlist_add</i><span>Artikel</span>",
                          "create_article_request_label": "<i class=\"material-icons\">playlist_add</i><span>Vorschlag</span>",
                          "create_template_label": "<i class=\"material-icons\">library_add</i><span>Vorlage</span>",
                          "criterion_label": "Hauptkriterium",
                          "delete_article": "Artikel löschen article",
                          "delete_article_confirmation": "Willst du diesen Artikel wirklich löschen?",
                          "delete_article_failed": "Artikel konnte nicht gelöscht werden. Überprüfe vor dem Löschen, dass der Artikel nicht öffentlich ist, also den Status \"Privat\" hat, bevor du ihn löschst.",
                          "delete_article_request": "Themenvorschlag löschen",
                          "delete_article_request_confirmation": "Willst du diesen Themenvorschlag wirklich löschen?",
                          "delete_block": "Artikel Block löschen",
                          "delete_block_confirmation": "Willst du diesen Block wirklich endgültig und unwiederbringlich löschen?",
                          "delete_template": "Artikelvorlage löschen",
                          "delete_template_confirmation": "Willst du diese Vorlage wirklich löschen?",
                          "description_label": "Beschreibung",
                          "draft_label": "Entwurf",
                          "element_title_add_criterion": "Kriterium hinzufügen",
                          "element_title_create_template": "Vorlage erstellen",
                          "instructions_label": "Weitere Anweisungen",
                          "instructions_by_provider_label": "Weitere Anweisungen des Anbieters",
                          "modal_accept_article_request": "Auftrag annehmen",
                          "modal_accept_article_request_text": "Wollen Sie den Artikelvorschlag des Anbieters annehmen und die Bearbeitung starten?",
                          "nav_articles_templates": "Vorlagen",
                          "nav_my_articles": "Meine Artikel",
                          "nav_requested_articles": "Meine Vorschläge",
                          "nav_all_requested_articles": "Aufträge",
                          "nav_suggested_articles": "Autorenvorschläge",
                          "no_articles": "Keine Artikel gefunden",
                          "no_orders": "Keine Aufträge gefunden",
                          "no_suggestions": "Keine Artikel bisher eingereicht",
                          "no_description": "Keine Beschreibung verfügbar",
                          "no_details": "Noch kein Inhalt erstellt",
                          "no_templates": "Noch keine Vorlagen erstellt",
                          "to_review": "Zu Prüfen",
                          "prompt": "Bitte gib folgende Informationen an:",
                          "publish_article": "Veröffentlichen",
                          "publish_article_title": "Ausgewählten Artikel veröffentlichen oder Veröffentlichung aufheben",
                          "publish_article_check_box_title": "Artikel auswählen",
                          "language_label": "Sprache",
                          "recipient_label": "Empfänger",
                          "recipient_domain_label": "Empfänger Domain",
                          "request": "Vorschlag erstellen",
                          "revoke_article_request": "Themenvorschlag entziehen",
                          "revoke_article_request_confirmation": "Willst du diesen Themenvorschlag wirklich dem aktuellen Autor wieder entziehen und erneut veröffentlichen?",
                          "select_article": "Bitte markiere mindestens einen Artikel, bevor du eine Aktion ausführst.",
                          "select_action": "Bitte wähle eine Aktion aus.",
                          "select_article_and_action": "Bitte wähle eine Aktion und markiere mindestens einen Artikel, bevor du die Aktion ausführst.",
                          "show_filter": "<span>Filter</span>&nbsp<i class=\"material-icons\">keyboard_arrow_down</i>",
                          "status_label_ACCEPTED": "Akzeptiert",
                          "status_label_DRAFT": "Entwurf",
                          "status_label_EXTERNAL": "Freigegeben für ",
                          "status_label_PUBLISHED": "Veröffentlicht",
                          "status_label_REQUESTED": "Themenvorschlag",
                          "status_label_SUGGESTED": "Themenvorschlag für ",
                          "status_label_SUBMITTED": "Zur Freigabe eingereicht für ",
                          "sub_criteria_label": "Unterkriterien",
                          "suggestion_for_improvment_modal_headline": "Verbesserungsvorschlag senden",
                          "suggestion_label": "Vorschlag",
                          "summary_label": "Zusammenfassung des Inhalts",
                          "summary_label_optional": "Zusammenfassung des Inhalts (optional)",
                          "tags_label": "Tags",
                          "template_label": "Vorlage",
                          "template_name_label": "Name der Vorlage",
                          "template_description_label": "Beschreibung",
                          "text_label": "Erstellen Sie hier Ihren Artikel",
                          "title_label": "Titel",
                          "toast_article_accepted": "Artikelvorschlag akzeptiert",
                          "toast_suggestion_for_improvement_sent": "Verbesserungsvorschlag gesendet",
                          "toast_template_saved": "Vorlage gespeichert",
                          "tooltip_accept_article": "Artikel zur Veröffentlichung freigeben",
                          "tooltip_accept_article_draft": "Artikelvorschlag annehmen",
                          "tooltip_accept_article_request": "Auftrag annehmen und Bearbeitung starten",
                          "tooltip_delete_article_request": "Auftrag löschen",
                          "tooltip_undo_article_submit": "Artikel zurückziehen",
                          "tooltip_revoke_article_request": "Auftrag dem aktuellen Autor entziehen",
                          "tooltip_suggest_improvement": "Verbesserungsvorschlag senden",
                          "tutorial_link": "Wie legt man einen neuen Artikel an?",
                          "tutorial_link_request": "Wie legt man einen Auftrag an?",
                          "tutorial_link_suggestion": "Wie reicht man einen Vorschlag ein?",
                          "validation_error_template_name_INVALID": "Der Name der Vorlage ist ungültig.",
                          "validation_error_template_name_MISSING": "Der Name der Vorlage fehlt.",
                          "validation_error_template_name_CONFLICT": "Der Name der Vorlage ist bereits vergeben.",
                          "validation_error_template_description_INVALID": "Die Beschreibung der Vorlage ist ungültig.",
                          "validation_error_template_criterion_INVALID": "Ein Hauptkriterium ist ungültig.",
                          "validation_error_template_subCriterion_INVALID": "Ein Unterkriterium ist ungültig.",
                          "validation_error_template_tags_INVALID": "Die Tags sind ungültig.",
                          "word_count_min_label": "Wortzahl (min)",
                          "word_count_max_label": "Wortzahl (max)",
                          "your_message": "Deine Nachricht"
                        },
                        "locales": {
                          "de": "Deutsch",
                          "en": "Englisch"
                        },
                        "deals": {
                          "ads_page": "Anzeigenseite",
                          "ads": "Anzeige",
                          "all_deals": "Alle Deals anzeigen",
                          "current_deals_overview": "Aktuelle Deals im Überblick",
                          "deals_of_day": "Deals des Tages",
                          "state": "Stand",
                          "further_info": "Weitere Infos",
                          "filter_results": "Ergebnisse filtern",
                          "report_change": "Änderung melden"
                        },
                        "general": {
                          "answer": "Antworten",
                          "cancel": "Abbrechen",
                          "change": "Ändern",
                          "clear": "Löschen",
                          "edit": "Bearbeiten",
                          "date_format_short": "dd.mm.yyyy",
                          "off": "Aus",
                          "ok": "Ok",
                          "on": "An",
                          "at": "am",
                          "from": "von {{name}}",
                          "from_date": "vom {{date}}",
                          "for": "für {{name}}",
                          "minimum_character_count_label": "Mindestens {{min}} Zeichen",
                          "months": [
                            "Januar",
                            "Februar",
                            "März",
                            "April",
                            "Mai",
                            "Juni",
                            "Juli",
                            "August",
                            "September",
                            "Oktober",
                            "November",
                            "Dezember"
                          ],
                          "months_short": [
                            "Jan",
                            "Feb",
                            "Mär",
                            "Apr",
                            "Mai",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Okt",
                            "Nov",
                            "Dez"
                          ],
                          "no_results": "Keine Ergebnisse</br> Wie schreibt man Artikel",
                          "private": "Privat",
                          "external": "Extern",
                          "rate": "Bewerten",
                          "save": "Speichern",
                          "send": "Senden",
                          "show_filter_label": "<span>Filter</span>&nbsp<i class=\"material-icons\">keyboard_arrow_down</i>",
                          "validation_info_missing": "Bitte gib einen Wert ein",
                          "validation_info_invalid": "Bitte halte Dich an das vorgegebene Format",
                          "weekdays": [
                            "Sonntag",
                            "Montag",
                            "Dienstag",
                            "Mittwoch",
                            "Donnerstag",
                            "Freitag",
                            "Samstag"
                          ],
                          "weekdays_short": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                          "weekdays_abbreviation": ["S", "M", "D", "M", "D", "F", "S"],
                          "yes": "Ja",
                          "no": "Nein",
                          "you": "Du"
                        },
                        "navigation": {
                          "article": "Artikel",
                          "article_title": "Artikel erstellen und überarbeiten",
                          "ask_question": "Frage stellen",
                          "back": "Zurück",
                          "chat_archive": "Archiv",
                          "chat_archive_title": "Archiv",
                          "chats": "Chats",
                          "chats_title": "Laufende Chats",
                          "create_article": "Artikel erstellen",
                          "create_chat": "Chat erstellen",
                          "create_text": "Text erstellen",
                          "dashboard": "Statistiken",
                          "dashboard_title": "Statistiken",
                          "fees": "Vergütung",
                          "from": "vom ",
                          "member_and_reader": "Mitglieder &amp; Leser",
                          "miscellaneous": "Sonstiges",
                          "more": "Mehr...",
                          "my_portal": "Mein Portal",
                          "my_profile": "Mein Profil",
                          "next": "Weiter",
                          "products": "Produkte",
                          "products_title": "Produkte suchen und Kunden bereitstellen",
                          "profile": "Profil",
                          "profile_title": "Profil",
                          "quacks": "Quacks",
                          "quacks_title": "Chatverwaltung",
                          "question": "Frage",
                          "questions": "Fragen",
                          "question_title": "Fragen ansehen oder stellen",
                          "read_more": "Mehr lesen",
                          "requests": "Anfragen",
                          "requests_title": "Offene Anfragen",
                          "settings": "Einstellungen",
                          "settings_title": "Einstellungen",
                          "to_start": "Zum Anfang",
                          "to_my_profile": "Zu meinem Profil"
                        },
                        "shortcuts": {
                          "selfintro": "Hallo, gerne helfe ich Ihnen weiter.",
                          "selfintro2": "Hallo, wie kann ich Ihnen helfen?",
                          "textHint1": "Das ist nur eine persönliche Empfehlung basierend auf meinen Erfahrungen. Ich empfehle jedoch, sich auch andere Quellen anzuschauen.",
                          "textThanks": "Danke für Ihre Anfrage. Ich hoffe Sie bald wieder bei uns begrüßen zu dürfen.",
                          "textClosing": "Beenden Sie den Chat mit einem Klick auf folgenden Button",
                          "textSharing": "Empfehlen Sie Produck.de gerne Ihrem sozialen Netzwerk. Mit einem Klick auf folgenden Button werden Ihnen die Buttons zum Teilen angezeigt und der Chat wird beendet.",
                          "toCustServ": "Bitte wenden Sie sich mit dieser Frage direkt an den Kundenservice.",
                          "pleaseWait": "Bitte erlauben Sie mir einen Moment, während ich es für Sie überprüfe.",
                          "thxForWaiting": "Vielen Dank, dass Sie gewartet haben.",
                          "furherQuestion": "Gibt es noch etwas, wobei ich Ihnen behilflich sein kann?"
                        },
                        "expertchatview": {
                          "create_chat": "<i class=\"material-icons\">playlist_add</i><span>Chat</span>",
                          "create_chat_title": "Neuen Chat erstellen",
                          "publish_quack": "Veröffentlichen",
                          "publish_quack_check_box_title": "Quack auswählen",
                          "show_filter": "<span>Filter</span><i class=\"material-icons\">keyboard_arrow_down</i>",
                          "show_filter_title": "Filter anzeigen"
                        },
                        "quackpage": {
                          "add_comment_label": "Kommentar hinzufügen",
                          "all_posts_overview": "<strong>Alle Beiträge<br />in der Übersicht anzeigen</strong>",
                          "answer_question": "Diese Frage beantworten",
                          "answer_question_title": "Teile Dein Wissen und beantworte diese Frage.",
                          "author_label": "Autor:",
                          "community_blog_description": "Im ProDuck Community Blog findest du Produkttests, Produktvergleiche, Produktchats und Antworten auf deine Produktfragen.",
                          "help_others": "Hilf anderen Nutzern, indem Du ihre Fragen beantwortest.",
                          "links_nav": "Links & Navigation",
                          "new_comment_label": "Ihr Kommentar...",
                          "new_question": "Neue Frage stellen",
                          "new_question_prompt": "Stelle eine neue Frage an die ProDuck Community.",
                          "most_valuable_answer": "Hilfreichste Anwort",
                          "minimum_hint": "Minimum nicht erreicht",
                          "profile_ref": "Zum Profil",
                          "question_overview": "Fragenübersicht",
                          "quacks-subheadline_1": "Übersicht mit Artikeln, Chats und Fragen aus der ProDuck Community",
                          "report": "Melden",
                          "report_post": "Beitrag melden",
                          "review_start": "Review starten",
                          "share": "Teilen",
                          "share_page": "Seite teilen",
                          "similar": "Ähnliches",
                          "similar_posts": "Ähnliche Beiträge",
                          "speciality": "Spezialgebiet",
                          "start_commenting_label": "Kommentieren",
                          "top_of_page": "Seitenanfang",
                          "to_top_of_page": "Zum Seitenanfang",
                          "valuable_answer": "Hilfreiche Anwort",
                        },
                        "questionsection": {
                          "answer_status_pos": "Beantwortet",
                          "answer_status_neg": "Unbeantwortet",
                          "edit_answer": "Antwort bearbeiten",
                          "edit_question": "Frage bearbeiten",
                          "nav_answerable_questions": "Alle Fragen",
                          "nav_my_answers": "Meine Antworten",
                          "nav_my_questions": "Meine Fragen",
                          "nav_new_question": "Frage stellen",
                          "no_details": "Es wurden keine weiteren Angaben zur Frage gemacht.",
                          "no_questions": "Keine Fragen gefunden",
                          "open_question_quack": "Frage öffnen und beantworten",
                          "tutorial_link": "Wie legt man einen neuen Artikel an?",
                          "prompt": "Bitte gib folgende Informationen an:",
                          "language_label": "Sprache",
                          "create_question_label": "<i class=\"material-icons\">playlist_add</i><span>Frage</span>",
                          "product_name_label": "Produktname",
                          "tags_label": "Tags",
                          "text_label": "Füge weitere Informationen hinzu, die zur Beantwortung Deiner Frage nötig sind!",
                          "topic_label": "Thema / Frage",
                          "send": "Veröffentlichen"
                        },
                        "settings": {
                          "account_delete": "Konto löschen",
                          "account_delete_abort": "Schön, dass du bei uns bleibst. ;)",
                          "account_delete_forever": "Möchten Sie Ihr Konto wirklich unwiderruflich löschen?",
                          "affiliate": "Affiliateeinstellungen",
                          "amazon_tag": "Amazon Partner-Tag",
                          "answer": "Antworten auf Fragen",
                          "application_status": "Bewerbungsstatus",
                          "application_status_request": "Haben Sie sich bereits bei ProDuck beworben und eine Zusage von einem ProDuck Mitarbeiter erhalten?",
                          "application_submit": "Bewerbung einreichen",
                          "application_submit_confirm": "Jetzt bewerben",
                          "application_submit_descr": "Du kannst dich jetzt bei ProDuck als verifizierter Autor bewerben. Klicke dafür nachfolgend auf \"{{buttonText}}\" um zum Bewerbungsformular weitergeleitet zu werden.",
                          "author_activation": "Freischaltung als Autor",
                          "author_activation_confirm": "Freischaltung beantragen",
                          "article_suggestion": "Artikelvorschlag",
                          "article_acceptance": "Freigabe von Artikeln",
                          "acceptance_info": "Im Folgenden können Sie wählen, ob Artikel, die von externen Autoren für Ihre Website eingereicht werden (nach erfolgreicher Verifizierung der Domain), von Ihnen genehmigt werden müssen, bevor sie auf der entsprechenden Domain veröffentlicht werden, oder ob sie sofort veröffentlicht werden sollen.",
                          "article_request_acceptance": "Akzeptierter Themenvorschlag",
                          "chat_acceptance": "Freigabe von Chats",
                          "chat_profile_settings": "Chat-Profileinstellungen",
                          "comment": "Kommentare zu Deinen Beiträgen",
                          "custom_affiliate_param_name": "Eigener Affiliate Parameter",
                          "custom_affiliate_param_value": "Wert des eigenen Parameters",
                          "e_mail_notifications": "Per E-Mail:",
                          "messages": "Chat Nachrichten",
                          "notifications": "Benachrichtigungen",
                          "push_notifications": "Auf Dein Mobilgerät:",
                          "requests": "Chat Anfragen"
                        },
                        "text": {
                          "about": "Über ProDuck",
                          "absent_mode": "Abwesenheitsmodus",
                          "absent_mode_failed": "Abwesenheitsmodus konnte nicht gesetzt werden. Bitte versuche es noch einmal.",
                          "accept": "Annehmen",
                          "accept_all": "Alle annehmen",
                          "account": "Konto",
                          "active_chats": "Aktive Chats",
                          "affiliate_link": "Affiliate-Link",
                          "apply_filter": "Filtern",
                          "author_upgrade_intro": "Werde <b>verifizierter Autor</b>, um folgende Funktionen nutzen zu können",
                          "author_upgrade_feature1": "Bezahlte Artikel für Anbieter schreiben.",
                          "author_upgrade_feature2": "Artikelvorschläge an Anbieter senden.",
                          "back_to_chatlog": "Zurück zum Chatverlauf",
                          "back_to_overview": "Zurück zur Übersicht",
                          "become_guestauthor": "Autor werden",
                          "become_partner": "Partner werden",
                          "become_xpert": "Experte werden",
                          "billing_address": "Rechnungsadresse",
                          "birthdate": "Geburtstag",
                          "business": "Geschäft",
                          "buy_on_amzn": "Bei Amazon kaufen*",
                          "view_on_amzn": "Bei Amazon ansehen*",
                          "carreer": "Karriere",
                          "cart": "Warenkorb",
                          "chat": "Home",
                          "chat_application_replaced": "Dein Chat wurde in einem anderen Fenster geöffnet.",
                          "chat_delete": "Chat löschen",
                          "chat_deletion_confirmation": "Willst du diesen Chat endgültig löschen?",
                          "chat_settings": "Chateinstellungen",
                          "chat_tutorial_link": "Wie starte ich einen Chat?",
                          "chat_usage_prompt": "Bitte beachte, dass ProDuck Experten unabhängig vom Shop agieren und dir bei fachlichen Produktfragen helfen. Bei Fragen zu persönlichen Bestellungen oder Online-Shops selbst, wende dich bitte direkt an den jeweiligen Kundenservice.",
                          "chat_usage_prompt2": "Bitte hab noch etwas Geduld. Dein Ansprechpartner ist bald da. Du kannst dein Browser Tab im Hintergrund belassen oder später erneut öffnen, um eingegangene Nachrichten zu empfangen.",
                          "chat_partner_info": "Du chattest mit ",
                          "company": "Firma",
                          "company_mandatory": "Firma*",
                          "confirm_choice": "Auswahl bestätigen",
                          "delete_block": "Block löschen",
                          "delete_block_confirm": "Wollen Sie diesen Block wirklich unwiderruflich löschen?",
                          "external_sites": "Externe Seiten / ",
                          "verify_sites": "Website verifizieren",
                          "quack_key": "Quack-Token",
                          "question_prompt": "Bitte formuliere deine Frage aus",
                          "chats": "Chats",
                          "chat_state": "Chat Status:",
                          "clear": "Löschen",
                          "close": "Schließen",
                          "closed": "Verbindung getrennt",
                          "close_chat": "Chat schließen",
                          "complete_form": "Bitte fülle einzelne oder mehrere Felder aus, um dein Profil zu vervollständigen.",
                          "complete_form_all": "Bitte fülle alle Felder aus, um das Upgrade zum Produktanbieter abzuschließen.",
                          "consultation_count": "Bisherige Beratungen: ",
                          "consultation_quality": "Beratungsqualität",
                          "contact": "Kontakt",
                          "contact_email": "Kontaktemail",
                          "contact_form": "Kontaktformular",
                          "contact_person": "Ansprechpartner",
                          "contact_phone": "Kontakttelefon",
                          "contact_user": "Chatpartner erneut kontaktieren",
                          "contact_user_info": "Du kannst versuchen den Nutzer erneut zu kontaktieren. Der Nutzer kann deine Nachrichten abrufen, falls er registriert ist.",
                          "contact_us": "Kooperationen, Produkttests und andere Anliegen - Kontaktieren Sie uns!",
                          "contact_us_descr": "Teilen Sie uns im Formular Ihr Anliegen mit. Wir werden uns schnellstmöglich bei Ihnen melden.",
                          "contact_us_successful": "Vielen Dank für Ihre Nachricht! Wir haben diese erhalten und werden uns schnellstmöglich bei Ihnen melden.",
                          "contact_us_failed": "Leider konnte die Nachricht nicht versendet werden. Bitte versuchen Sie es später noch einmal!",
                          "contact_us_application": "Deine Bewerbung bei ProDuck",
                          "contact_us_application_descr": "Sende uns dein aussagekräftiges Bewerbungsschreiben. Wir prüfen dieses umgehend und melden uns zeitnah bei dir.",
                          "container_info": "Finde passende Produkte zu deiner Anfrage",
                          "content_not_available": "Leider kann ich aktuell keinen passenden Experten zu deiner Fragestellung finden. Bitte versuche es später erneut.",
                          "content_not_found": "Leider konnte ich deinen gewählten Ansprechpartner nicht finden, bitte überprüfe deinen Link oder kontaktiere unseren Support über das <a href=\"https://www.produck.de/contact.html\">Kontaktformular.</a>",
                          "content_offline": "Dein Ansprechpartner ist im Moment offline.",
                          "content_postpone": "Ein Ansprechpartner wird dir baldmöglichst antworten. Halte dieses Browserfenster offen oder im Hintergrund.",
                          "continue_chat": "Chat fortführen",
                          "connecting": "Verbinden...",
                          "cookie_info": "Diese Website verwendet Cookies, um Dir ein individuelles Nutzererlebnis bieten zu können, Anzeigen zu personalisieren, soziale Medien einzubinden und Nutzungsdaten zu analysieren. Dies dient vornehmlich der generellen Funktion und stetigen Verbesserung unseres Services. Durch einen Klick auf &quot;Alle annehmen&quot; stimmst Du der Verwendung aller Cookies zu. Über &quot;Einstellungen&quot; kannst du eine benutzerdefinierte Auswahl vornehmen.",
                          "cookie_label_more": "Mehr",
                          "cookie_label_more_title": "Einstellungen und Informationen",
                          "cookie_label_general": "Allgemein",
                          "cookie_label_required": "Erforderlich",
                          "cookie_label_improvement": "Verbesserung",
                          "cookie_descr_general": "Cookies sind kleine Textdateien, die im World Wide Web auf vielfältige Art und Weise eingesetzt werden. Damit die Seite von Produck funktionieren kann, werden Cookies von ProDuck in deinem Browser gespeichert.<br/>Genauere Informationen findest Du in den Abschnitten &quot;Erforderlich&quot; und &quot;Improvement&quot; sowie in unseren <a href=\"/agb.html\">AGB</a> und den <a href=\"/data-sec.html\">Datenschutzbestimmungen</a>.<br/>Wenn sie später die hier getroffenen Einstellungen widerrufen oder ändern möchten, können Sie in den Datenschutzbestimmungen über einen Link im Abschnitt &quot;Widerruf / Widerspruch&quot; gesetzte Cookies löschen und anschließend durch Neuladen von produck.de diesen Dialog erneut aufrufen.",
                          "cookie_descr_required": "Ohne die Verwendung von Cookies funktionieren der Chat, Login und Textbearbeitungsfunktionen auf produck.de nicht.<br/>Nur durch die Verwendung von Cookies können wir eine gesicherte und stabile Kommunikation in unserem Chat anbieten, die sogar bei Unterbrechungen der Internetverbindung aufrechterhalten wird. Das geht nur, weil in einem Cookie die nötigen Informationen gespeichert werden, um den Chat nach dem Neuladen der Seite wiederherzustellen.<br/>Zudem werden nach dem Klick auf &quot;Annehmen&quot; Deine hier getätigten Einstellungen in einem Cookie gespeichert.",
                          "cookie_descr_improvement": "Weitere Cookies werden eingesetzt, um Informationen darüber zu sammeln, wie sich Besucher auf produck.de verhalten. Dazu gehören zum Beispiel die von Google Analytics gespeicherten. Die gesammelten Daten sind anonymisiert und werden eingesetzt, um das Layout und die Benutzerführung auf produck.de zu verbessern.",
                          "cookies_removed": "Cookies wurden entfernt.",
                          "costs": "Ausgaben",
                          "current_rating": "beträgt deine aktuelle Gesamtbewertung von Kunden",
                          "current_version": "Aktuelle Version",
                          "customer": "Kunde",
                          "customer_id": "Chat-ID",
                          "dashboard": "Statistiken",
                          "data_security": "Datenschutz",
                          "date_of_creation": "Erstellungsdatum",
                          "date_until": "Datum (bis)",
                          "day": "Tag",
                          "decline": "Ablehnen",
                          "decline_requests": "Anfragen ablehnen",
                          "decline_requests_with_alternatives": "Anfragen ablehnen und alternative Kontaktmöglichkeiten anzeigen",
                          "delete_account": "Konto löschen",
                          "delete_token": "Token löschen",
                          "descr_request": "Gib deine Anfrage ein",
                          "developer_lnk": "Für Entwickler",
                          "docu": "Anleitungen",
                          "done": "Ok",
                          "domain_error": "Das Domainformat ist inkorrekt. Es sollte im Format \"example.com\" sein.",
                          "domain_success": "Domain okay.",
                          "edit_password": "Passwort ändern",
                          "open_profile": "Profil öffnen",
                          "edit_profile": "Profil bearbeiten",
                          "edit_username": "Nutzernamen ändern",
                          "email": "Email",
                          "email_error": "Email bereits vergeben.",
                          "email_notification": "E-Mail Benachrichtigung",
                          "email_success": "Email verfügbar",
                          "email_string": "hiduck@monstec.de",
                          "eng": "Englisch",
                          "EXPERT": "Experte",
                          "CUSTOMER": "Nutzer",
                          "expert_not_available": "Kein Experte verfügbar",
                          "expert_not_found": "Experte nicht gefunden",
                          "expert_offline": "Experte aktuell beschäftigt",
                          "expired": "abgelaufen",
                          "faq": "FAQ",
                          "first_name": "Vorname",
                          "from": "Von",
                          "generate": "Generieren",
                          "generate_token": "Token generieren",
                          "ger": "Deutsch",
                          "goodbye": "Verabschiedung",
                          "guest_access": "Gast-zugang",
                          "guest_acc_info": "Hier gelangst du als Gast direkt zum Chat",
                          "how_to": "Wie funktioniert Produck.de?",
                          "illegal_domain": "Domain nicht zulässig",
                          "image_link": "Bild-Link",
                          "image_link_tagged": "Affiliate-Bild-Link",
                          "jump_chat_expl": "Mit Klick auf den folgenden Button startest du einen unabhängigen <a href=\"https://www.jump.chat/c/\" target=\"_blank\">Jump Chat</a>, der sich besonders eignet, wenn dein Chatpartner den Chat per Mobiltelefon beitreten und keine App installieren möchte.",
                          "widget": "Widget",
                          "widget_blank": "Widget ohne Text",
                          "widget_image": "Nur Bild",
                          "widget_large": "Widget groß",
                          "income": "Einnahmen",
                          "index_page": "Startseite",
                          "imprint": "Impressum",
                          "imprint_link": "Links & Impressum",
                          "insert_mode": "Nachrichten einfügen",
                          "insert_mode_end": "Einfügen beenden",
                          "language": "Sprache",
                          "last_name": "Nachname",
                          "later": "Später",
                          "leave_email": "Email hinterlegen",
                          "leave_form": "Kontaktformularadresse hinterlegen",
                          "leave_number": "Nummer hinterlegen",
                          "link_generator": "Link Generator",
                          "link_invalid": "Eingabe ungültig",
                          "link_optional": "Link (optional)",
                          "login": "Login",
                          "login_with": "Einloggen mit ",
                          "login_info": "Hier kannst du dich direkt einloggen",
                          "login2": "Zum Login",
                          "logout": "Abmelden",
                          "maximum_no_chats": "Sie haben die maximale Anzahl an Chats erreicht.",
                          "membership": "Mitglied seit",
                          "message": "Nachricht",
                          "message_delete": "Nachricht löschen",
                          "message_history_info": "Willst du diese Nachricht aus dem Verlauf löschen",
                          "munich": "München",
                          "new_chat_alert": "Neuer Chat Alarm",
                          "new_chat_notification": "Neue Chatnachricht",
                          "new_password": "Neues Passwort",
                          "quack_activated": "Quack aktiviert",
                          "quacks_activated": "Quacks aktiviert",
                          "quack_deactivated": "Quack deaktiviert",
                          "quacks_tutorial_link": "Wie erstelle ich einen neuen Chatdialog?",
                          "new_request": "Neue Anfrage",
                          "new_username": "Neuer Nutzername",
                          "newsletter_opt_in": "Ich möchte relevante Informationen zu Produkten und Dienstleistungen sowie andere Inhalte, die für mich von Interesse sein könnten, von ProDuck erhalten. Ich kann diese Benachrichtigungen jederzeit abbestellen.",
                          "no": "Nein",
                          "no_chat": "Du hast aktuell keinen aktiven Chat",
                          "no_chats": "Keine Chats gefunden",
                          "no_chats_yet": "Noch keine Chats vorhanden",
                          "no_data_available": "Veröffentliche Artikel, Chats oder Frage, um deine Statistiken zu aktivieren.",
                          "no_data_retrieved": "Es konnten keine Daten von ProDuck erhalten werden. Bitte versuchen Sie es später erneut.",
                          "no_requests": "Du hast keine offenen Chatanfragen",
                          "no_service": "Service nicht verfügbar",
                          "no_token": "Bisher wurden keine Partner Domains mit Quack-Token gefunden. Legen Sie hier neue Domains im Format \"example.com\" an und speichern Sie über den Button \"{{buttonName}}\"",
                          "no_domain": "Keine Domain gefunden. Möglicherweise wurde bisher keine externe Partnerseite in ihrem Account hinterlegt. Diese können Sie in den Einstellungen im Menüpunkt \"Externe Seiten\" eintragen.",
                          "notifications": "Benachrichtigungen",
                          "offer": "Leistungen",
                          "operating_hours": "Beratungszeiten:",
                          "or": "oder",
                          "original_version": "Originalversion",
                          "password": "Passwort",
                          "password_error": "Passwort entspricht nicht den Anforderungen (mind. 8 Zeichen, Klein- und Großbuchtstaben, 1 Sonderzeichen, 1 Zahl)",
                          "password_error2": "Passwörter nicht identisch",
                          "password_forgotten": "Passwort vergessen",
                          "password_pattern": "Muss mindestens 8 Zeichen in Klein- und Großbuchtstaben, 1 Sonderzeichen und 1 Zahl enthalten",
                          "password_success": "Passwort in Ordnung.",
                          "password_success2": "Passwörter identisch",
                          "password_username_error": "Deine Email oder dein Passwort ist falsch.",
                          "payment": "Bezahlung",
                          "payment_details": "Bezahldaten",
                          "paymentHint": "Bitte hinterlege deine Kontodaten, damit wir dir eine Gutschrift der Provision ausstellen können.",
                          "paypal_mandatory": "Paypal Client ID*",
                          "phone": "Telefon",
                          "phone_string": "+49 174 3500996",
                          "placeholder_chat": "Schreibe eine Nachricht",
                          "prev_password": "Altes Passwort",
                          "press": "Presse",
                          "pricing": "Preise",
                          "product_provider": "Produktanbieter",
                          "product_ref": "zur Produktseite",
                          "product": "Produkt",
                          "product_edit": "Produkt bearbeiten",
                          "product_chat": "Produktchat",
                          "product_category_mandatory": "Produktkategorie*",
                          "product_name": "Produktname",
                          "product_link": "Produktlink",
                          "product_link_or_asin": "Produktlink oder ASIN",
                          "article_overview": "Artikelübersicht",
                          "product_test": "Produkttest",
                          "profession": "Beruf",
                          "profile": "Profil",
                          "profile_instruction": "Bitte fülle die nachfolgenden Felder aus, um dir ein Profil anzulegen.<br/><br/>Du kannst zusätzliche Text- und Media-Blöcke hinzufügen, auf denen du z.B. Produkte oder Dienstleistungen vorstellen kannst. (<a href=\"/profile/2127\" target=\"_blank\">Beispielprofil ansehen</a>)<br/><br/>Deinen Text kannst du zudem mit ausgewählten HTML-Codes individuell gestalten. Eine Übersicht aller zulässigen HTML-Codes findest du <a href=\"/docu/profile.html#beautify-profile\" target=\"_blank\">hier</a>.<br/><br/>Speichere das Ergebnis abschließend mit Klick auf \"Profil speichern\" unten.",
                          "PROVIDER": "Anbieter",
                          "provider_upgrade_intro": "Werde <b>Anbieter</b>, um folgende Features zu nutzen:",
                          "provider_upgrade_feature1": "Lasse Artikel von verifizierten Autoren schreiben.",
                          "provider_upgrade_feature2": "Lege Produkte und Dienstleistungen für deine Kunden an.",
                          "provider_upgrade_feature3": "Verkaufe Produkte und Dienstleitungen direkt im Chat via Paypal.",
                          "provider_upgrade_feature4": "Lege Teams mit eigenen Mitarbeitern oder ProDuck Experten für deinen Kundenservice-Chat an.",
                          "publish_quack": "Stelle deinen Dialog online, um weiteren Kunden zu helfen!",
                          "publish_quack_btn": "Veröffentlichen",
                          "publish_quack_title": "Ausgewählten Quack veröffentlichen oder Veröffentlichung aufheben",
                          "quack_error": "Der Server hat einen Fehler gemeldet. Bitte stelle sicher, dass du einen Titel und maximal 5 Tags (insgesamt nicht mehr als 495 Zeichen) angegeben hast und versuche es erneut. Andernfalls kontaktiere unseren Kundenservice über das <a href=\"https://www.produck.de/contact.html\">Kontaktformular.</a>",
                          "quack_published": "Dein Quack wurde erfolgreich veröffentlicht. Du kannst das Chatfenster nun schließen.",
                          "question": "Frage",
                          "questions_shortcut": "Weitere Fragen",
                          "quit_chat": "Chat beenden",
                          "rating": "Bewertung",
                          "recent_quacks": "Aktuelle Quacks",
                          "recent_topics": "Aktuelle Chats",
                          "recommend": "Vielen Dank, dass du den Service von ProDuck genutzt hast. Wir würden uns freuen, wenn du uns deinen Freunden, Bekannten und weiteren Shops empfiehlst.",
                          "recommendation": "Empfehlung",
                          "register": "Registrieren",
                          "register_headline_cust": "Als Kunde",
                          "register_headline_exp": "Als Experte oder Produktanbieter",
                          "register_slogan_cust": "Unsere Produktexperten beraten dich kostenlos zu deinem Anliegen. <br /><span>Kostenlos Registrieren</span>",
                          "register_slogan_exp": "Unterstütze dein Umfeld beim Produktkauf und profitiere von großzügigen Provisionen. <br /><span>Kostenlos Registrieren</span>",
                          "register_notification_1": "Bitte überprüfe: Dein Nutzername darf Buchstaben, Zahlen und Bindestriche enthalten. Bindestriche dürfen nicht am Anfang, Ende oder direkt nacheinander stehen. Weitere Sonderzeichen sind nicht erlaubt. Auch der Name \"Ducky\" darf nicht im Namen vorkommen. Bei weiter bestehenden Problemen, wende dich bitte an info@monstec.de.",
                          "register_notification_2": "Bitte überprüfe, ob du deine Rolle im Auswahlmenü \"Account\" bereits gewählt hast. Bei weiter bestehenden Problemen, wende dich bitte an info@monstec.de.",
                          "repeat_password": "Neues Passwort wiederholen",
                          "request_accepted_by_sibling": "Du hast die Anfrage bereits in einem anderen Fenster oder auf einem anderen Gerät angenommen.",
                          "request_activation": "Freischaltung beantragen",
                          "request_preempted": "Ein anderer Experte hat die Anfrage bereits angenommen.",
                          "request_cancelled": "Der Benutzer hat die Anfrage abgebrochen.",
                          "request_declined": "Leider wurde deine Anfrage von keinem Experten angenommen. Wir empfehlen dir, es zu einem sp%C3%A4teren Zeitpunkt nochmal zu probieren.",
                          "request_declined_by_sibling": "Du hast die Anfrage bereits in einem anderen Fenster oder auf einem anderen Gerät abgelehnt.",
                          "request_info": "Du hast eine Beratungsanfrage:",
                          "request_topic_application": "Dein Bewerbungsschreiben",
                          "request_process_err": "Die Anfrage konnte vom Server nicht korrekt verarbeitet werden. Die Seite wird neu geladen.",
                          "request_remove": "Anfrage entfernen",
                          "request_remove_all": "Abgelaufene Anfragen entfernen",
                          "request_remove_all_enquiry": "Willst Du alle abgelaufenen Anfragen dauerhaft entfernen?",
                          "request_remove_enquiry": "Willst Du die Anfrage wirklich dauerhaft entfernen?",
                          "request_topic": "Ihr Anliegen",
                          "request_to_self": "Sie können keine Anfrage an sich selbst stellen, wenn sie eingeloggt sind. Versuchen Sie zum Beispiel einen anderen Browser oder ein Inkognito-Fenster.",
                          "sales_last_60": "Verkäufe der letzten 60 Tage",
                          "search": "Suchen",
                          "search_btn": "Expertenchat starten",
                          "search_shop_id": "Shop-ID suchen",
                          "send": "Senden",
                          "service_not_reachable": "Service nicht erreichbar",
                          "share": "Teilen",
                          "share_link": "Link teilen",
                          "share_channel": "Mein Produck.de-Channel:",
                          "shortcuts": "Chat Schnellauswahl",
                          "slogan": "Die Produktplattform",
                          "start_chat": "Chat starten",
                          "status": "Status: ",
                          "stay_here": "Hier bleiben",
                          "msg_status": [
                            "wird gesendet",
                            "Fehler beim Senden",
                            "übermittelt",
                            "empfangen",
                            "gelesen"
                          ],
                          "store_quack": "Du kannst nun diesen Chat veröffentlichen. Wähle dafür eine passende Überschrift, die Sprache des Chats und die Zielseite. Bestätige anschließend mit \"Veröffentlichen\". Du kannst Quacks auch nachträglich bearbeiten und deaktivieren.",
                          "store_requests": "Anfragen in die Liste aufnehmen",
                          "store_requests_with_alternatives": "Anfragen in die Liste aufnehmen und alternative Kontaktmöglichkeiten anzeigen",
                          "support": "Support",
                          "table_of_content": "Inhaltsverzeichnis",
                          "terms": "AGB",
                          "terms_opt_in": "Indem Sie unten auf \"Senden\" klicken, stimmen Sie zu, dass ProDuck die oben angegebenen persönlichen Daten speichert und verarbeitet, um Ihnen die angeforderten Inhalte bereitzustellen. Hier finden Sie unsere <a href=\"/data-sec.html\" target=\"_blank\" tabindex=\"-1\">Datenschutzbestimmungen</a>.",
                          "terms_opt_in_author": "Mit Klick auf \"{{confirmBtnText}}\" bestätigst du, dass du ProDuck's <a href=\"/agb-autoren.html\" target=\"_blank\" tabindex=\"-1\">Allgemeine Geschäftsbedingungen für Autoren</a> gelesen und verstanden hast und diesen zustimmst.",
                          "text_field_validation": "Das Feld darf keine Sonderzeichen außer Bindestrich, Punkt und Leerzeichen enthalten",
                          "tidy_up_list": "Liste aufräumen",
                          "thanks": "Bedanken",
                          "thank_you": "Vielen Dank ",
                          "to_chat": "Zum Chat",
                          "to_cust_service": "Kundenservice",
                          "topic": "Thema",
                          "try_again": "Neue Anfrage stellen",
                          "try_later": "Dein Ansprechpartner ist im Moment beschäftigt.",
                          "tutorials": "Tutorials",
                          "under_construction": "Dieser Bereich befindet sich aktuell in Arbeit.",
                          "update_password": "Passwort aktualisieren",
                          "update_username": "Nutzername aktualisieren",
                          "upgrade": "Upgrade",
                          "upgrade_account_author": "Zum Autor upgraden",
                          "upgrade_account_provider": "Zum Anbieter upgraden",
                          "upgrade_account_successful": "Ihr Konto wurde erfolgreich upgegraded. Bitte loggen Sie sich neu ein!",
                          "upgrade_modal_headline": "Konto upgraden",
                          "upgrade_modal_text": "Um diese Funktion nutzen zu können, ist ein Upgrade zum Anbieter-Account nötig. Sie können dies in den Einstellungen im Abschnitt Konto erledigen.",
                          "username": "Nutzername",
                          "username_error": "Nutzername bereits vergeben",
                          "username_pattern": "Der Nutzername darf keine Umlaute, Sonderzeichen oder Leerzeichen enthalten",
                          "username_success": "Nutzername verfügbar",
                          "wait": "Dein Experte ist gleich da",
                          "wait_shortcut": "Bitte warten",
                          "wait_over_shortcut": "Warten vorbei",
                          "website": "Website",
                          "website_cv_application": "Website oder CV-Link",
                          "website_mandatory": "Website*",
                          "weekdays": [
                            "Sonntag",
                            "Montag",
                            "Dienstag",
                            "Mittwoch",
                            "Donnerstag",
                            "Freitag",
                            "Samstag"
                          ],
                          "weekdaysAbbrev": ["S", "M", "T", "W", "T", "F", "S"],
                          "weekdaysShort": ["So", "Mon", "Di", "Mi", "Do", "Fr", "Sa"],
                          "welcome": "Begrüßung",
                          "welcome2": "Begrüßung mit Frage",
                          "writing": "schreibt...",
                          "xpert": "Experte",
                          "yes_delete": "Ja, löschen",
                          "registration": "Registrierung",
                          "registration_successful": "Registrierung erfolgreich",
                          "congrats": "Glückwunsch",
                          "registration_succeeded": "Deine Registrierung war erfolgreich.",
                          "registration_instruction": "Deine Registrierung ist erst abgeschlossen, wenn du deine E-Mail-Adresse anhand des von Produck.de an dich versandten Bestätigungslinks verifizierst. Danach kannst du dich auf Produck.de mit deiner E-Mail-Adresse und deinem Passwort anmelden und alle Funktionen nutzen.",
                          "chat_discuss": "Artikel im Chat besprechen",
                          "chat_discuss_txt": "Besprechen",
                          "buy": "Kaufen",
                          "chat_to_offer": "Zum Angebot",
                          "send_partner": "An Chatpartner senden",
                          "save": "Speichern",
                          "descr_self": "Beschreibe dich...",
                          "descr_prod": "Kurzbeschreibung des Produktes",
                          "copy": "Kopieren",
                          "hello": "Hallo",
                          "nickname": "Nickname:",
                          "expertise": "Spezialist / Experte für ...",
                          "title": "Titel",
                          "section_title": "Titel des Blocks für die Navigation (nur 1 Wort)",
                          "headline": "Überschrift",
                          "pic_url": "Profilbild-Url",
                          "media_url": "Bild-Url",
                          "background_pic_url": "Hintergrundbild-Url",
                          "choose": "Zieldomain wählen",
                          "selectChat": "Bitte markiere mindestens einen Chat, bevor du eine Aktion ausführst.",
                          "selectAction": "Bitte wähle eine Aktion aus.",
                          "selectChatNAction": "Bitte wähle eine Aktion und markiere mindestens einen Chat, bevor du die Aktion ausführst.",
                          "time": "Uhrzeit",
                          "video_chat_start": "Videochat starten",
                          "vet_chat": "Onlinesprechstunde",
                          "views": "Ansichten",
                          "words": "Wörter"
                        },
                        "icon": {
                          "account_circle": "<i class=\"material-icons\">account_circle</i>",
                          "add_alert": "<i class=\"material-icons\">add_alert</i>",
                          "add": "<i class=\"material-icons\">add</i>",
                          "chat": "<i class=\"material-icons\">chat</i>",
                          "chat_bubble_outline": "<i class=\"material-icons md-light\">chat_bubble_outline</i>",
                          "chrome_reader_mode": "<i class=\"material-icons\">chrome_reader_mode</i>",
                          "checkmark": "<i class=\"material-icons\">check_circle</i>",
                          "dashboard": "<i class=\"material-icons md-light\">dashboard</i>",
                          "description": "<i class=\"material-icons md-light\">description</i>",
                          "edit": "<i class=\"material-icons md-light\">edit</i>",
                          "email": "<i class=\"material-icons\">email</i>",
                          "euro_symbol": "<i class=\"material-icons\">euro_symbol</i>",
                          "exit_to_app": "<i class=\"material-icons\">exit_to_app</i>",
                          "help": "<i class=\"material-icons\">help</i>",
                          "key": "<i class=\"material-icons\">vpn_key</i>",
                          "location": "<i class=\"material-icons\">location_on</i>",
                          "lock": "<i class=\"material-icons\">lock</i>",
                          "link": "<i class=\"material-icons\">link</i>",
                          "notifications": "<i class=\"material-icons\">notifications</i>",
                          "phone": "<i class=\"material-icons\">phone</i>",
                          "settings": "<i class=\"material-icons md-light\">settings</i>",
                          "search": "<i class=\"material-icons md-light\">search</i>",
                          "stars": "<i class=\"material-icons md-light\">stars</i>",
                          "video_chat": "<i class=\"material-icons\">videocam</i>",
                          "video_chat_left": "<i class=\"material-icons left\">videocam</i>"
                        },
                        "indexpage": {
                          "affiliate_tag_steps": "Fügen Sie Ihren Amazon-Affiliate-Tag zu Ihren ProDuck-Profil-Einstellungen hinzu und tragen Sie die neue Website in Ihrem Amazon PartnerNet-Konto ein. Sie können die Transaktionen in Ihrem Amazon-Konto sehen. Zusätzlich empfehlen wir, die Links auf externen Seiten zu kontrollieren, um sicherzustellen, dass andere Skripte Ihr Affiliate-Tag nicht überschreiben.",
                          "ai_market_transformation": "Der Aufstieg der KI hat das Marktumfeld verändert. Suchmaschinen priorisieren zunehmend Autoritätsseiten und nutzergenerierte Inhalte, was die Erfolgschancen für rein informative Seiten minimiert. KI-generierte Antworten halten Leser davon ab, die Seiten der Autoren zu besuchen. Viele Blogs spüren die Auswirkungen, und dies ist wahrscheinlich erst der Anfang.",
                          "avoid_background_tasks": "Vergessen Sie ablenkende Hintergrundaufgaben",
                          "benefits_for_hosts": "Welche Vorteile haben Blog-Hosts?",
                          "benefits_summary": "Vorteile auf einen Blick",
                          "beta_phase_started": "Beta-Phase gestartet: Begrenzter kostenloser Zugang",
                          "blog_host_benefits": "Auch Blog-Hosts profitieren – mit einem Anstieg des Traffics, verbesserten Cross-Site-Conversions und erhöhten Werbeeinnahmen. Steigern Sie Ihr Marketing mit ProDuck!",
                          "content_cooperation": "Content-Kooperationen zwischen Blogs sind nicht neu. Allerdings birgt das Hosting Ihrer Inhalte auf externen Seiten stets das Risiko, die Hoheit darüber zu verlieren. So kam es schon vor, dass Autoren von Seitenadministratoren gesperrt wurden und den Zugang zu ihren Inhalten und damit ihren Einnahmen verloren. Die Urheberschaft nachzuweisen und Inhalte von fremden Seiten zu entfernen, kann ein langwieriger Prozess sein.",
                          "content_hub_solution": "Der Content-Hub von ProDuck bietet eine schnelle und mühelose Lösung. Mit nur wenigen Klicks können Sie Ihre Inhalte auf erfolgreiche Blogs verschieben, die trotz immer widerkehrender Marktherausforderungen solide performen.",
                          "content_still_hosted": "Wenn dies geschieht, kann Ihr Inhalt vorübergehend auf ProDuck.de gehostet werden, bis ein neuer Blog-Host gefunden wird.",
                          "content_system_control": "Mit dem Content-System von ProDuck bleiben Sie der alleinige Administrator Ihrer Inhalte. Sie können Ihre Inhalte jederzeit von jeder Plattform entfernen. So haben nicht nur Website-Besitzer die Möglichkeit ihre Inhalte zu deaktivieren, sondern auch Sie selbst. Behalten Sie somit die Kontrolle und Sicherheit mit ProDuck.",
                          "content_tailored_audience": "In der heutigen digitalen Landschaft gehen erfolgreiche Webseiten über reinen Text hinaus und bieten ein reichhaltiges Multimedia-Erlebnis. ProDuck ermöglicht es Ihnen, Ihre Inhalte mit Produkt-Widgets, Videos und Bildern zu bereichern und bietet verschiedene Content-Typen wie Artikel, Chats, Q&As und Nachrichten. Vermitteln Sie Ihre Botschaft effektiv und nutzen Sie Gestaltungsmöglichkeiten wie Rich-Snippets in Suchmaschinen, um die Sichtbarkeit Ihrer Inhalte zu steigern.",
                          "create_and_manage_content": "Schritt 2: Erstellen und verwalten Sie Ihre ersten Inhalte",
                          "create_content_today": "Jetzt erste Beiträge erstellen",
                          "diverse_content_creation": "Erstellen Sie AI-unterstützt <u>vielfältige Inhalte</u> wie Artikel, Chats, Q&As, Nachrichten oder Deals, um Ihre Follower nach deren Vorlieben zu informieren.",
                          "does_produck_own_copyright": "Besitzt ProDuck.de das Urheberrecht an meinen Inhalten und können sie es mir wegnehmen?",
                          "effortless_content_creation": "Möchten Sie sich nicht auf eine einzige Nische festlegen? ProDuck macht es Ihnen einfach, top-rankende Artikel in verschiedenen Kategorien zu schreiben, sei es über ein Fahrrad, eine Kaffeemaschine oder einen Saugroboter und das in verschiedenen Sprachen. Finden Sie die perfekten Nischenblogs, um Ihre Inhalte zu hosten, und sorgen Sie so für maximale Sichtbarkeit und Engagement. Durch die Zusammenarbeit mit etablierten Blogs wird ihr Umsatz deutlich profitieren - Das zeigen bisherige Erfahrungen.",
                          "ensure_affiliate_tag": "Wie kann ich sicherstellen, dass mein Affiliate-Tag auf externen Seiten verwendet wird?",
                          "exclusive_offer": "Exklusives Angebot endet in:",
                          "expand_content_reach": "Reichweite Ihrer Inhalte erweitern",
                          "export_import_inconvenient": "Das Exportieren und Importieren von Inhalten gibt dem neuen Blog-Besitzer die Kontrolle über Ihre Inhalte und ist viel umständlicher als das Übertragen eines Beitrags mit nur zwei Klicks mit ProDuck.",
                          "further_questions": "Haben Sie weitere Fragen?",
                          "find_wordpress_partner": "Schritt 3: Finden Sie einen geeigneten WordPress-Partnerblog und teilen Sie Ihre Inhalte extern oder veröffentlichen Sie sie direkt auf ProDuck.",
                          "flexibility": "Flexibilität",
                          "flexibility_equals_success": "Flexibilität &#61; Erfolg",
                          "focus_on_content": "Fokus auf Inhalte",
                          "focus_on_content_only": "Fokus auf Ihre Inhalte",
                          "full_control": "Volle Kontrolle",
                          "get_full_access_now": "Erhalten Sie jetzt vollen Zugriff",
                          "google_penalties": "Nach unserem Wissen werden die Google-Penalties für \"schlechten\" Inhalt beim Wechsel zu einem neuen Blog nicht automatisch übertragen. Google kann jedoch den Inhalt neu bewerten und möglicherweise die Strafe erneut verhängen, was das Ranking des neuen Blogs beeinträchtigen könnte. Autoren und Blog-Hosts sollten sicherstellen, dass sie qualitativ hochwertige Inhalte anbieten und potenziell minderwertiges und schädliches Material herausfiltern.",
                          "how_to_start": "Wie starte ich",
                          "included_services": "Mit ProDuck sind viele dieser Dienste standardmäßig enthalten. Während wir nicht jedes spezialisierte Plugin ersetzen können, streben wir ein effizientes Setup an, das Ihre Investition maximiert.",
                          "integrated_features": "Domains, Webspace, Plugins, Analysetools etc. – all das summiert sich zu erheblichen Ausgaben. Machen Sie sich frei von unzähligen Abonnements und sparen Sie Geld durch die Nutzung der <u>integrierten Funktionen von ProDuck</u>.",
                          "limited_free_access": "Seien Sie unter den ersten 1.000 Nutzern, die kostenlosen Zugang genießen! Melden Sie sich jetzt an, um das volle Potenzial Ihrer Inhalte mit ProDuck freizuschalten. Nutzen Sie dieses zeitlich begrenzte Angebot und treten Sie der wachsenden Community erfolgreicher Content-Ersteller bei. Verpassen Sie es nicht – handeln Sie jetzt!",
                          "maximize_output": "Maximieren Sie Ihre Leistung",
                          "more": "Mehr...",
                          "more_reach": "Mehr Reichweite",
                          "multi_content_management": "Multi-Content-Management",
                          "multi_content_types": "Multi-Content-Typen",
                          "next_steps": "<strong>Nächste Schritte:</strong>",
                          "new_articles": "Neue Artikel",
                          "outsource_without_losing_control": "Lagern Sie Ihre Inhalte aus, <u>ohne die Kontrolle zu verlieren</u>. Blog-Eigentümer, die Ihre Texte hosten, können Ihr Material nicht für sich beanspruchen oder ändern. Sie können jedoch Ihre Einreichungen annehmen oder ablehnen und über interne Nachrichten oder Chats Änderungen vorschlagen.",
                          "performance_drop": "Hat die Leistung Ihres Blogs nachgelassen und Sie möchten das nächste Mal vorbereitet sein? Bleiben Sie in Zeiten rascher Veränderungen flexibel. Als ProDuck-Blogger kann Ihr <u>Inhalt mit nur wenigen Klicks auf eine andere Seite verschoben</u> oder direkt auf ProDuck veröffentlicht werden.",
                          "plugin_free": "Momentan ist das WordPress-Plugin völlig kostenlos.",
                          "platform_management": "Wir verwalten und <u>entwickeln die Plattform für Sie</u>, sodass Sie lästige IT- und SEO-Aufgaben nicht weiter stören. Nutzen Sie Funktionen wie Markup-Sprache, Rich Snippets, Cookie-Consent, Produkt-Widgets für Ihre eigenen oder Amazon-Produkte und umfassende Analysen.",
                          "powerful_features": "Unsere Plattform bietet eine Fülle von leistungsstarken Funktionen, viele davon standardmäßig integriert und andere anpassbar an Ihre Bedürfnisse. Beispielsweise können Sie ganz einfach Amazon Widgets mit Ihren eigenen Affiliate-Tags in Ihre Texte einbetten.",
                          "produck_retain_rights": "Ähnlich wie bei Plattformen wie Reddit oder YouTube behält ProDuck das Recht, die Inhalte wiederzuverwenden, aber es wäre nachteilig und gegen die Interessen des Dienstes, das Eigentum zu beanspruchen oder es Ihnen zu entziehen.",
                          "publish_in_various_niches": "Dies ermöglicht es Ihnen zudem, gleichzeitig in verschiedenen Nischen, Sprachen und Stilen zu veröffentlichen, ohne mehrere Blogs verwalten zu müssen.",
                          "cost_of_wordpress_plugin": "Was kostet das WordPress-Plugin?",
                          "followers_notified": "Werden Follower sowohl über interne als auch externe Veröffentlichungen benachrichtigt?",
                          "followers_notified_details": "Ja, Ihre Follower werden regelmäßig über alle Ihre Veröffentlichungen informiert, unabhängig davon, ob diese intern oder extern sind.",
                          "hosts_benefit": "Blog-Hosts profitieren von erhöhtem Traffic, was zu höheren Werbeeinnahmen, mehr Followern und verbesserten Cross-Selling-Möglichkeiten führt.",
                          "what_happens_if_host_removes_content": "Was passiert, wenn der Blog-Host meinen Inhalt entfernt?",
                          "what_happens_if_submission_declined": "Was passiert, wenn mein eingereichter Beitrag vom Blog-Host abgelehnt wird?",
                          "why_not_export_import": "Warum nicht einfach Inhalte zwischen WordPress-Blogs exportieren und importieren?",
                          "ready_to_get_started": "Bereit zum Start?",
                          "redirect_penalties": "Führt die 301-Weiterleitung zur Weitergabe von Google-Penalties an die Zielseite?",
                          "reposition_content": "Inhalte mit wenig Traffic leicht <u>an aussichtsreichere Orte verschieben</u>: Befreien Sie Ihre Inhalte davon, an eine einzige Seite gebunden zu sein. Unser Content-Distribution-Service ermöglicht es Ihnen, Ihre Inhalte auf den geeignetsten und leistungsstärksten Blogs zu platzieren.",
                          "run_a_blog": "Ein Blog zu führen, bringt unzählige kleine Aufgaben mit sich, die viel Zeit und Aufmerksamkeit beanspruchen. Wie YouTube oder Medium befreit Sie der Content-Hub von ProDuck von diesen Ablenkungen und ermöglicht es Ihnen, sich auf das zu konzentrieren, was wirklich zählt – Ihre Inhalte.",
                          "save_money": "Geld sparen",
                          "save_money_blog": "Sparen Sie Ihr Geld",
                          "secure_your_content": "Sichern Sie Ihre Inhalte: Kontrolle über Ihr geistiges Eigentum behalten",
                          "sign_up_for_free": "Kostenlos registrieren",
                          "sign_up_step": "Schritt 1: Melden Sie sich kostenlos an und erstellen Sie Ihr Konto",
                          "start_free": "Kostenlos starten",
                          "stay_ahead_with_plan_b": "Mit Plan B immer einen Schritt voraus",
                          "stay_ahead_with_updates": "Bei ProDuck sind wir immer einen Schritt voraus. Unser System entwickelt sich kontinuierlich weiter, um neuen Trends gerecht zu werden, und bietet häufige Feature-Updates, damit Sie immer die besten Tools zur Verfügung haben.",
                          "submission_declined": "Wenn Ihr eingereichter Beitrag abgelehnt wird, werden Sie benachrichtigt. Sie können dann Ihre Inhalte verfeinern und erneut einreichen oder nach einem anderen geeigneten Blog suchen.",
                          "tailored_content": "Inhalte zugeschnitten auf Ihre Leser",
                          "track_performance": "Schritt 4: Verfolgen Sie die Leistung und passen Sie sie bei Bedarf an",
                          "trending_articles": "Trendartikel",
                          "unanswered_questions": "Unbeantwortete Fragen",
                          "wordpress_blog_costs": "Jeder, der schon einmal einen erfolgreichen WordPress-Blog gehostet hat, weiß, dass die Kosten für hilfreiche Erweiterungen und Upgrades mit wachsender Besucherzahl steigen.",
                          "your_content_your_decision": "Ihre Inhalte, Ihre Entscheidung"
                        },
                        "toasts": {
                          "absent_mode_saved": "Abwesenheitsmodus gespeichert",
                          "chat_activated": "Chat aktiviert",
                          "chat_deactivated": "Chat deaktiviert",
                          "check_input": "Bitte überprüfe Deine Angaben",
                          "chips": "+Tag (max 5) und mit Enter bestätigen",
                          "data_missing": "Fehlende Angaben",
                          "data_invalid": "Angaben fehlerhaft",
                          "data_updated": "Daten gespeichert",
                          "data_update_failed": "Daten speichern fehlgeschlagen",
                          "delete_article_accomplished": "Artikel gelöscht",
                          "times_twisted": "Bitte korrigiere: Die jeweiligen Anfangszeiten müssen vor den dazugehörigen Endzeiten liegen.",
                          "error": "Es ist ein Fehler aufgetreten",
                          "language_updated": "Sprache aktualisiert",
                          "message_deleted": "Nachricht gelöscht",
                          "message_updated": "Nachricht aktualisiert",
                          "no_access": "Kein Zugriff",
                          "no_data_found": "Keine Daten gefunden",
                          "password_change_fail": "Passwort ändern fehlgeschlagen. Probiere es noch einmal.",
                          "password_change_success": "Passwort erfolgreich geändert.",
                          "password_incorrect": "Das angegebene aktuelle Passwort ist nicht korrekt.",
                          "reject": "Ablehnen",
                          "rejected": "Abgelehnt",
                          "tags_updated": "Tags aktualisiert",
                          "title_updated": "Titel aktualisiert",
                          "too_many_bytes": "Datei zu groß (>10MB)",
                          "unsupported_filetype": "Dateityp wird nicht unterstützt.",
                          "upload_failed": "Upload fehlgeschlagen",
                          "username_change_fail": "Benutzername ändern fehlgeschlagen. Probiere es noch einmal.",
                          "username_change_success": "Benutzername erfolgreich geändert.",
                          "error_msg": {
                            "error_occurred": "Uppps - Da ist was schief gelaufen!",
                            "try_again": "Bitte versuchen Sie erneut, {{action}}. Über den folgenden Link gelangen Sie zurück.",
                            "reset_password": "Ihr Passwort zurückzusetzen",
                            "login": "sich anzumelden",
                            "register": "sich zu registrieren"
                          }
                        },
                        "metadata": {
                          "profile_generator_title": "Profilgenerator zur Erstellung eines persönlichen Profils auf ProDuck.",
                          "profile_generator_description": "Nutzer können sich auf ProDuck ein eigenes Profil anlegen und dieses veröffentlichen."
                        },
                        "modals": {
                          "chat_left": " hat den Chat verlassen.",
                          "chat_left_sibling_session": "Du hast den Chat verlassen.",
                          "session_ended": "Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein."
                        },
                        "html": {
                          "affiliate_note": "<p id=\"affiliate-note\">* Bitte beachten Sie, dass Links auf dieser Seite Links zu Werbepartnern sein k&ouml;nnen. F&uuml;r K&auml;ufe, die &uuml;ber einen dieser Links zustande kommen, erhalten wir (falls sie die Marketingcookies des Werbepartners annehmen) Provision. Ihnen entstehen dadurch keine zus&auml;tzlichen Kosten. Sie unterst&uuml;tzen jedoch unseren Service (<a href=\"/docu/general.html#affiliate-links\" rel=\"nofollow\" target=\"_blank\">mehr erfahren</a>). Preise, Lieferbedingungen und Verf&uuml;gbarkeiten entsprechen dem angegebenen Stand (Datum/Uhrzeit) und k&ouml;nnen sich jederzeit &auml;ndern. Angaben auf unserer Seite weichen daher ggf. von denen der Partnerseiten ab. F&uuml;r den Kauf eines betreffenden Produkts gelten die Angaben zu Preis und Verf&uuml;gbarkeit, die zum Kaufzeitpunkt auf der/den ma&szlig;geblichen Website(s) (z.B. Amazon) angezeigt werden. Bestimmte Inhalte, die auf dieser Website angezeigt werden, stammen von Amazon. Diese Inhalte werden 'wie besehen' bereitgestellt und k&ouml;nnen jederzeit ge&auml;ndert oder entfernt werden.</p>",
                          "chat_name_request": "Mit welchem Namen darf dich der Experte ansprechen:<input class=\"user-conversation-name\" placeholder=\"Name\"></input>",
                          "contact_proposal1": "Du erreichst ihn bzw. sie alternativ per E-Mail oder Telefon unter",
                          "contact_proposal2": "Du erreichst ihn bzw. sie alternativ per E-Mail unter",
                          "contact_proposal3": "Du erreichst ihn bzw. sie alternativ per Telefon unter",
                          "contact_proposal4": "Bitte versuche es später erneut.",
                          "upgrade_account_paypal_hint": "Um eine Beratungsleistung direkt mit Paypal abzurechnen, brauchen Sie noch einen Paypal Unternehmensaccount und eine Client-Id. Diese können Sie dann in den Einstellungen >> Bezahlung hinterlegen, so dass alle Bezahlungen anschließend bei Ihnen ankommen. Ihre Paypal Client-ID finden Sie hier: <a href=\"https://developer.paypal.com/docs/archive/checkout/integrate/#replace-sandbox-credentials-with-live-credentials\" target=\"_blank\">Paypal Live Credentials</a> Schritt 1-6 erstellen (Dauer ca. 2 Minuten).",
                          "usage_info": "Durch die Verwendung dieses Services stimmst Du den <a href=\"/agb.html\" target=\"_blank\" rel=\"noopener noreferrer\">AGB</a> und <a href=\"/data-sec.html\" target=\"_blank\" rel=\"noopener noreferrer\">Datenschutzbestimmungen</a> von ProDuck zu.",
                          "request_submitted": "Ich habe dein Anliegen&nbsp;<b>{{topic}}</b>&nbsp;nun an unsere Experten übermittelt.",
                          "login_invitation": "Bitte <a href=\"https://www.produck.de/signup.html\">registriere</a> Dich oder <a href=\"https://www.produck.de/login.html\">logge</a> Dich ein, um die volle Funktionalität von ProDuck zu nutzen."
                        },
                        "profilepage": {
                          "account_deleted_successfully": "Dein Konto wurde erfolgreich gelöscht",
                          "account_deletion_failed": "Dein Konto konnte nicht gelöscht werden. Überprüfe deine Internetverbindung und versuche es noch einmal.",
                          "chat_start": "Chat starten",
                          "create_own_profile": "Eigenes Profil anlegen",
                          "data_update_failed": "Daten konnten nicht aktualisiert werden. Fehlermeldung: ",
                          "expert": "Experte",
                          "heading_1": "- Überschrift 1 -",
                          "heading_2": "- Überschrift 2 -",
                          "heading_3": "- Überschrift 3 -",
                          "how_to_write_article_for_domain": "Wie schreibe ich einen Artikel für {{domain}}",
                          "media_block_add": "Medienblock hinzufügen",
                          "preview": "Vorschau",
                          "profile": "Profil",
                          "profile_overview": "Profilübersicht",
                          "profile_page_of": "Profilseite von ",
                          "profile_save": "Profil speichern",
                          "register": "Registrieren",
                          "suggest_article_to_domain": "{{domain}} einen Artikel vorschlagen",
                          "text_block_add": "Textblock hinzufügen",
                          "title": "Titel"
                        },
                        "order": {
                          "back_to_chat": "Zurück zum Chat",
                          "back_to_cart": "Zurück zum Warenkorb",
                          "your_order": "Deine Bestellung",
                          "cart_overview": "Dein Warenkorb",
                          "update": "aktualisieren",
                          "remove_all": "alle löschen",
                          "checkout": "Zur Kasse",
                          "order_and_delivery": "Deine Rechnungs- und Lieferadresse",
                          "first_name": "Vorname*",
                          "last_name": "Nachname*",
                          "city": "Ort*",
                          "street": "Straße*",
                          "zip_code": "PLZ*",
                          "house_no": "Hausnummer*",
                          "info_characters": "",
                          "info_error_characters": "Bitte benutze nur Buchstaben.",
                          "info_error_no": "Format: 30 bzw. 30a",
                          "info_error_no2": "Bitte benutze genau 5 Ziffern.",
                          "email": "Email*",
                          "phone": "Telefonnummer",
                          "terms": "Ich erkläre mich mit den <a href=\"/agb.html\" target=\"_blank\" tabindex=\"-1\">AGB</a> und den <a href=\"/data-sec.html\" target=\"_blank\" tabindex=\"-1\">Datenschutzbestimmungen</a> von Produck.de einverstanden.",
                          "confirm_data": "Daten bestätigen",
                          "incl_tax": "*Alle Preise inkl. MwSt., der Versand ist kostenlos. Sofort lieferbar. Lieferung erfolgt innerhalb von 1-3 Tagen.",
                          "pay_adv": "Vorkasse",
                          "check_and_order": "Alles korrekt?",
                          "verify_data": "Bitte überprüfe deine Bestellung und klicke dann auf die Schaltfläche \"Jetzt kaufen\". Wenn du deine Bestellung noch ändern willst, kannst du auf \"Bestellung bearbeiten\" klicken und gelangst so zurück zur Produktauswahl.",
                          "submit_order": "Jetzt kaufen",
                          "shipment": "Versandkosten",
                          "edit_order": "Bestellung bearbeiten",
                          "total": "Gesamtpreis (inkl. MwSt.)",
                          "buy_notification": "Ich beabsichtige diesen Artikel zu kaufen",
                          "buy_confirmation_self": "Vielen Dank. Der Experte hat deine Bezahlung erhalten.",
                          "buy_confirmation_partner": "Der Kunde hat folgenden Artikel erfolgreich gekauft: ",
                          "cart_value": "Warenkorbpreis:",
                          "really_remove1": "Willst du diesen Artikel aus deinem Warenkorb entfernen?",
                          "really_remove2": "Durch das L%c3%b6schen des Warenkorbs werden alle Artikel aus deinem Warenkorb unwiderruflich gel%c3%b6scht. Willst du fortfahren?",
                          "amount": "Bitte gib deine gewünschte Anzahl ein",
                          "advisor_note1": "Du wurdest beraten von ",
                          "advisor_note2": ". Kontaktlink: https://www.produck.de/chat.html?cid=",
                          "thanks_for_buy": "Produck.de bedankt sich für deinen Einkauf.",
                          "request": "Anfragen"
                        },
                          "shariff": {
                            "mailBody1": "Hi, gerne m%c3%b6chte ich dir den Service von {url} empfehlen. Dort kann man sich zu verschiedensten Produkten beraten lassen und Produkte im Chat erwerben. Probier's bei Gelegenheit mal aus!",
                            "mailBody2": "Hi, über folgenden Link bin ich erreichbar",
                            "shareQuack": "Meine Empfehlung",
                            "title": "ProDuck - The expert among the online portals"
                          },
                    },                
                },
                en: {
                    translation: {
                        "articlesection": {
                        "accepted_by": "Accepted by user ",
                        "add_block": "Add block",
                        "add_criterion_label": "+ Criterion",
                        "add_template_label" : "+ Template",
                        "article_outsourced": "Article externally released",
                        "article_published": "Article published",
                        "article_type_label": "Type",
                        "article_unpublished": "Article unpublished",
                        "author_label": "Author:",
                        "choose_target_site_for": "<span class=\"flex-box w100 fs-12\">Choose the target site for your publication of: </span><span class=\"flex-box w100 fs-14\"><strong>\"{{title}}\"</strong></span>",
                        "create": "Create draft",
                        "create_article_label": "<i class=\"material-icons\">playlist_add</i><span>Article</span>",
                        "create_article_request_label" : "<i class=\"material-icons\">playlist_add</i><span>Suggestion</span>",
                        "create_template_label" : "<i class=\"material-icons\">library_add</i><span>Template</span>",
                        "criterion_label": "Main criterion",
                        "delete_article": "Delete article",
                        "delete_article_confirmation": "Do you really want to delete this article?",
                        "delete_article_failed": "The article could not be deleted. Before deleting, make sure that the article is not public and has the status \"Private\".",
                        "delete_article_request": "Delete article request",
                        "delete_article_request_confirmation": "Do you really want to delete this article request?",
                        "delete_block": "Delete article block",
                        "delete_block_confirmation": "Do you really want to delete this block completely and permanently?",
                        "delete_template": "Delete article template",
                        "delete_template_confirmation": "Do you really want to delete this template?",
                        "description_label": "Description",
                        "draft_label": "Draft",
                        "element_title_add_criterion": "Add a criterion",
                        "element_title_create_template": "Create a template",
                        "instructions_label": "Further Instructions",
                        "instructions_by_provider_label": "Further instructions of the provider",
                        "modal_accept_article_request": "Accept order",
                        "modal_accept_article_request_text" : "Do you want to accept the suggested article and start editing?",
                        "nav_articles_templates": "Templates",
                        "nav_my_articles": "My articles",
                        "nav_requested_articles": "My Suggestions",
                        "nav_all_requested_articles": "Orders",
                        "nav_suggested_articles": "Author Suggestions",
                        "no_articles": "No articles found",
                        "no_orders": "No orders found",
                        "no_suggestions": "No suggestions found, yet",
                        "no_description": "No description available",
                        "no_details": "No content defined yet",
                        "no_templates": "No templates created yet",
                        "to_review": "To Review",
                        "prompt": "Please provide the following information:",
                        "publish_article": "Publish",
                        "publish_article_title": "Publish or unpublish the selected article",
                        "publish_article_check_box_title": "Select article",
                        "language_label": "Language",
                        "recipient_label": "Recipient",
                        "recipient_domain_label": "Recipient Domain",
                        "request": "Create suggestion",
                        "revoke_article_request": "Revoke article request",
                        "revoke_article_request_confirmation": "Do you really want to revoke the article request from the current author and so re-publish it?",
                        "select_article": "Please select at least one article, before choosing an action.",
                        "select_action": "Please choose an action.",
                        "select_article_and_action": "Please choose an action and select at least one article before publishing.",
                        "show_filter": "<span>Filter</span><i class=\"material-icons\">keyboard_arrow_down</i>",
                        "status_label_ACCEPTED": "Accepted",
                        "status_label_DRAFT": "Draft",
                        "status_label_EXTERNAL": "Released for ",
                        "status_label_PUBLISHED": "Published",
                        "status_label_REQUESTED": "Topic suggestion",
                        "status_label_SUGGESTED": "Topic suggested for ",
                        "status_label_SUBMITTED": "Submitted for release on ",
                        "sub_criteria_label": "Sub-criteria",
                        "suggestion_for_improvment_modal_headline": "Send suggestion for improvement",
                        "suggestion_label": "Suggestion",
                        "summary_label": "Summary of content",
                        "summary_label_optional": "Summary of content (optional)",
                        "tags_label": "Tags",
                        "template_label": "Template",
                        "template_name_label": "Name of template",
                        "template_description_label": "Description",
                        "text_label": "Create your article here",
                        "title_label": "Title",
                        "toast_article_accepted": "Suggested article accepted",
                        "toast_suggestion_for_improvement_sent": "Suggestion for improvement sent",
                        "toast_template_saved": "Template saved",
                        "tooltip_accept_article": "Approve article for publication",
                        "tooltip_accept_article_draft": "Accept article suggestion",
                        "tooltip_accept_article_request": "Accept order and start editing the article",
                        "tooltip_delete_article_request": "Delete order",
                        "tooltip_undo_article_submit": "Undo article submission",
                        "tooltip_revoke_article_request": "Revoke the order from the current author",
                        "tooltip_suggest_improvement": "Send suggestion for improvement",
                        "tutorial_link": "How to create a new article?",
                        "tutorial_link_request": "How to create an order?",
                        "tutorial_link_suggestion": "How to submit a suggestion?",
                        "validation_error_template_name_INVALID": "The template-name is invalid.",
                        "validation_error_template_name_MISSING": "The template-name is missing.",
                        "validation_error_template_name_CONFLICT": "The template-name is already in use.",
                        "validation_error_template_description_INVALID": "The template-description is invalid.",
                        "validation_error_template_criterion_INVALID": "A criterion is invalid.",
                        "validation_error_template_subCriterion_INVALID": "A sub-criterion is invalid.",
                        "validation_error_template_tags_INVALID": "The tags are invalid.",
                        "word_count_min_label": "Word-count (min)",
                        "word_count_max_label": "Word-count (max)",
                        "your_message": "Your message"
                    },
                    "locales": {
                        "de": "German",
                        "en": "English"
                    },                    
                    "deals":{
                        "ads_page": "Ads Page",
                        "ads": "Ads",
                        "all_deals": "View all deals",
                        "current_deals_overview": "Current Deals Overview",
                        "deals_of_day": "Deals of the day",
                        "state": "Last Upd.",
                        "further_info": "Further Info",
                        "filter_results": "Filter results",
                        "report_change": "Report change"
                    },
                    "general": {
                        "at": "at",
                        "answer": "Answer",
                        "cancel": "Cancel",
                        "change": "Change",
                        "clear": "Clear",
                        "edit": "Edit",
                        "date_format_short": "mm/dd/yyyy",
                        "off": "Off",
                        "ok": "Ok",
                        "on": "On",
                        "from": "by {{name}}",
                        "from_date": "from {{date}}",
                        "for": "for {{name}}",
                        "minimum_character_count_label": "At least {{min}} characters",
                        "months": ["January","February","March","April","May","June","July","August","September","October","November","December"],
                        "months_short": ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
                        "no_results": "No results",
                        "private": "Private",
                        "external": "External",
                        "rate": "Rate",
                        "save": "Save",
                        "send": "Send",
                        "show_filter_label": "<span>Filter</span>&nbsp<i class=\"material-icons\">keyboard_arrow_down</i>",
                        "validation_info_missing": "Please enter a value",
                        "validation_info_invalid": "Please adhere to the given format",
                        "weekdays": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                        "weekdays_short": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                        "weekdays_abbreviation": ["S", "M", "T", "W", "T", "F", "S"],
                        "yes": "Yes",
                        "no": "No",
                        "you": "You"
                    },
                    "navigation": {
                        "article": "Article",
                        "article_title": "Create or change articles",
                        "ask_question": "Ask Question", 
                        "back": "Back",
                        "chat_archive": "Archive",
                        "chat_archive_title": "Archive",
                        "chats": "Chats",
                        "chats_title": "Ongoing chats",
                        "create_article": "Create Article",
                        "create_chat": "Create Chat",
                        "create_text": "Create Text",
                        "dashboard": "Statistics",
                        "dashboard_title": "Statistics",
                        "fees": "Commissions",
                        "from": "from ",
                        "member_and_reader": "Members &amp; Readers",
                        "miscellaneous": "Misc",
                        "more": "More...",
                        "my_portal": "My Portal",
                        "my_profile": "My Profile",
                        "next": "Next",
                        "products": "Products",
                        "products_title": "Search products and push them to the chat",
                        "profile": "Profile",
                        "profile_title": "Profile",
                        "quacks": "Quacks",
                        "quacks_title": "Chat Management",
                        "question": "Question",
                        "questions": "Questions",
                        "question_title": "View or ask questions",
                        "read_more": "Read more",
                        "requests": "Requests",
                        "requests_title": "Open requests",
                        "settings": "Settings",
                        "settings_title": "Settings",
                        "to_start": "To first page",
                        "to_my_profile": "Go to my Profile"
                    },
                    "shortcuts": {
                        "selfintro": "Hello, I am happy to help you.",
                        "selfintro2": "Hello, how can I assist you?",
                        "textHint1": "This is just a personal recommendation based on my experience. However, I recommend that you also check other sources.",
                        "textThanks": "Thank you for your inquiry. I hope to welcome you back soon.",
                        "textClosing": "End the chat by clicking the following button",
                        "textSharing": "Feel free to recommend Produck.de to your social network. By clicking the following button, the share buttons will be displayed and the chat will be closed.",
                        "toCustServ": "Please contact customer service directly with this question.",
                        "pleaseWait": "Please give me a moment while I check this for you.",
                        "thxForWaiting": "Thank you for waiting.",
                        "furherQuestion": "Is there anything else I can assist you with?"
                    },
                    "expertchatview": {
                        "create_chat": "+&nbsp;Chat",
                        "create_chat_title": "Create new chat",
                        "publish_quack": "Publish",
                        "publish_quack_check_box_title": "Select Quack",
                        "show_filter": "<span>Filter</span><i class=\"material-icons\">keyboard_arrow_down</i>",
                        "show_filter_title": "Show filter"
                      },
                      "quackpage": {
                        "add_comment_label": "Add comment",
                        "all_posts_overview": "<strong>View all posts<br />in the overview</strong>",
                        "answer_question": "Answer this question",
                        "answer_question_title": "Share your knowledge and answer this question.",
                        "author_label": "Author:",
                        "community_blog_description": "In the ProDuck community blog, you will find product tests, product comparisons, product chats, and answers to your product questions.",
                        "help_others": "Help other users by answering their questions.",
                        "most_valuable_answer": "Best answer",
                        "minimum_hint": "Minimum not reached",
                        "links_nav": "Links & Navigation",
                        "new_comment_label": "Your comment...",
                        "new_question": "Ask a new question",
                        "new_question_prompt": "Ask a new question to the ProDuck community.",
                        "profile_ref": "Go to profile",
                        "question_overview": "Question overview",
                        "quacks-subheadline_1": "Overview with articles, chats, and questions from the ProDuck community",
                        "report": "Report",
                        "report_post": "Report post",
                        "review_start": "Start Review",
                        "share": "Share",
                        "share_page": "Share page",
                        "similar": "Related",
                        "similar_posts": "Related Posts",
                        "speciality": "Speciality",
                        "start_commenting_label": "$t(icon.add) Comment",
                        "top_of_page": "Top of Page",
                        "to_top_of_page": "To top of page",
                        "valuable_answer": "Helpful answer"
                    },
                    "questionsection": {
                        "answer_status_pos": "Answered",
                        "answer_status_neg": "Unanswered",
                        "create_question_label": "<i class=\"material-icons\">add</i><span>Question</span>",
                        "edit_answer": "Edit answer",
                        "edit_question": "Edit question",
                        "nav_answerable_questions": "All questions",
                        "nav_my_answers": "My answers",
                        "nav_my_questions": "My questions",
                        "nav_new_question": "Ask question",
                        "language_label": "Language",
                        "no_details": "No further details provided on the question.",
                        "no_questions": "Found no questions",
                        "open_question_quack": "Open question and answer",
                        "prompt": "Please provide the following information:",
                        "product_name_label": "Name of product",
                        "tags_label": "Tags",
                        "text_label": "Include all the information someone requires to answer your question",
                        "topic_label": "Topic / Question",
                        "tutorial_link": "How to create a new question?",
                        "send": "Publish"
                    },
                    "settings": {
                        "account_delete": "Delete account",
                        "account_delete_forever": "Do you really want to delete your account irrevocably?",
                        "account_delete_abort": "Glad you're staying with us. ;)",
                        "affiliate": "Affiliate-settings",
                        "amazon_tag": "Amazon Partner-Tag",
                        "answer": "answers to questions",
                        "application_status": "Application Status",
                        "application_status_request": "Have you already applied to ProDuck and received a confirmation from a ProDuck employee?",
                        "application_submit": "Submit Application",
                        "application_submit_confirm": "Apply now",
                        "application_submit_descr": "You can now apply to ProDuck as a verified author. Click on \"{{buttonText}}\" below to get forwarded to the application form.",
                        "author_activation": "Activation as author",
                        "author_activation_confirm": "Request activation",
                        "article_suggestion": "Suggested article",
                        "article_acceptance": "Approval of articles",
                        "acceptance_info": "In the following, you can choose whether articles submitted by external authors for publication on your site (after successful verification of the domain) need to be approved by you before being published on the corresponding domain, or if they should be published immediately.",
                        "article_request_acceptance": "Accepted article request",
                        "chat_acceptance": "Approval of chats",
                        "chat_profile_settings": "Chat Profile Settings",
                        "comment": "comments to your entries",
                        "custom_affiliate_param_name": "Custom affiliate parameter",
                        "custom_affiliate_param_value": "Value of the custom parameter",
                        "e_mail_notifications": "By e-mail:",
                        "messages": "Chat messages",
                        "notifications": "Notifications",
                        "push_notifications": "To your mobile device:",
                        "requests":"Chat requests"
                    },
                    "text": {
                        "about": "About ProDuck",
                        "absent_mode": "Absent Mode",
                        "absent_mode_failed": "Setting the absent mode failed. Please, try again.",
                        "accept": "Accept",
                        "accept_all": "Accept all",
                        "account": "Account",
                        "active_chats": "Active Chats",
                        "affiliate_link": "Affiliate Link",
                        "apply_filter": "Apply",
                        "article_overview": "Article Overview",
                        "author_upgrade_intro": "Become <b>verified author</b> in order to use the following features:",
                        "author_upgrade_feature1": "Write payed articles for provider.",
                        "author_upgrade_feature2": "Suggest articles to provider.", 
                        "back_to_chatlog": "Back to the chatlog",
                        "back_to_overview": "Back to overview",
                        "become_guestauthor": "Become Author",
                        "become_partner": "Become Partner",
                        "become_xpert": "Become Expert",
                        "billing_address": "Billing address",
                        "birthdate": "Birthdate",
                        "business": "Business",
                        "buy_on_amzn":"Buy on Amazon*",
                        "view_on_amzn": "View on Amazon*",
                        "carreer": "Carreer",
                        "cart": "Cart",
                        "chat": "Home",
                        "chat_application_replaced": "Your chat has been continued in another window.",
                        "chat_delete": "Delete chat",
                        "chat_deletion_confirmation": "Do you want to permanently delete the chat?",
                        "chat_settings": "Chat Settings",
                        "chat_tutorial_link": "How to start a chat?",
                        "chat_usage_prompt": "Please consider that ProDuck experts act independent of any shop and help you to answer productrelated questions. For all questions concerning your personal order or the shop itself, please contact the shops' customer service.",
                        "chat_usage_prompt2": "Please be patient. Your expert is coming soon. Keep the browser window open or in the background. The chat can even be continued after a page reload.",
                        "chat_partner_info": "You are chatting with ",
                        "company": "Company",
                        "company_mandatory": "Company*",
                        "confirm_choice": "Confirm choice",
                        "delete_block": "Delete Block",
                        "delete_block_confirm": "Do you really want to permanently delete this block?",
                        "external_sites": "External Sites \/ ",
                        "verify_sites": "Verify Website",
                        "quack_key": "Quack-Keys",
                        "question_prompt": "Please formulate a question",
                        "chats": "Chats",
                        "chat_state": "Chat State:",
                        "clear": "Clear",
                        "close": "Close",
                        "closed": "Connection disconnected",
                        "close_chat": "Close chat",
                        "complete_form": "Fill in single or multiple fields, to complete your profile.",
                        "complete_form_all": "Please fill in all fields, in order to complete the upgrade to product product provider.",
                        "consultation_count": "Past Consultations: ",
                        "consultation_quality": "Consultation Quality",
                        "contact": "Contact",
                        "contact_email": "Contact Email",
                        "contact_form": "Contact Form",
                        "contact_person": "Contact Person",
                        "contact_phone": "Contact Phone",
                        "contact_user": "Contact this user",
                        "contact_user_info": "Try to contact this user. In case he/she is a registered member, he/she will receive your message",
                        "contact_us": "Cooperations, Product Tests or any other request - Get in touch!",
                        "contact_us_descr": "Let us know what you are up to and we will get back to you soon.",
                        "contact_us_successful": "Thank you for your message! We have received it and will get back to you as soon as possible.",
                        "contact_us_failed": "Unfortunately, the message could not be sent. Please try again later!",
                        "contact_us_application": "Your application at ProDuck",
                        "contact_us_application_descr": "Send us your informative letter of application. We will check it immediately and get back to you soon.",
                        "container_info": "Find suitable products to your search",
                        "content_not_available": "We can't find any matching expert to your request. Please try again later.",
                        "content_not_found": "Your contacted partner could not be found, please verify the link or contact our support team via the <a href=\"https://produck.de/contact.html\">contact form.</a>.",
                        "content_offline": "Your contacted partner is currently offline.",
                        "content_postpone": "Your expert will respond soon. Keep the browser window open or in the background.",
                        "continue_chat": "Continue chat",
                        "connecting": "Connecting...",
                        "cookie_info": "This website uses cookies, to be able to provide a distinct user experience, personialise advertisements, integrate social media and analyse data traffic. This is primarily serves the purpose of improving our service and the performance of the site. By clicking &quot;Accept all&quot; you agree to the usage of all cookies. Via the settings you can customize your choice.",
                        "cookie_label_more": "More",
                        "cookie_label_more_title": "Preferences and information",
                        "cookie_label_general": "General",
                        "cookie_label_required": "Required",
                        "cookie_label_improvement": "Improvement",
                        "cookie_descr_general": "Cookies are small text files, that are used in many different ways all over the world wide web. In order to enable a proper working of the site produck.de, Produck is saving cookies in your browser.<br/>You can find more information in the sections &quot;Required&quot; and &quot;Improvement&quot; as well as in our href=\"/agb.html\">terms</a> and the <a href=\"/data-sec.html\">dataprotection regulations</a>.<br/>If you want to change your settings or revoke your consent later, you may delete set cookies by the help of a link in the dataprotection regulations in section 1.11. Afterwards you can bring up this dialog by reloading produck.de.",
                        "cookie_descr_required": "Without the utilisation of cookies the chat, login and text editing functions on produck.de are not able to work.<br/>Only by utilising cookies we can offer a safe and stable communication in the chat, even when the internet connection is interrupted. This is only possible through a cookie that will contain the necessary information needed to reopen the chat after the reload.<br/>Hence, the preferences you can set here by clicking &quot;Accept&quot; are saved in a cookie.",
                        "cookie_descr_improvement": "Other cookies like the ones set by Google Analytics are in place to collect information on the behaviour of visitors of produck.de. The collected data are anonymised and will be used to improve the layout and the user experience on produck.de",
                        "cookies_removed": "Cookies have been removed.",
                        "costs": "Costs",
                        "current_rating": "reflects your current overall customer rating",
                        "current_version": "Current version",
                        "customer": "Customer",
                        "customer_id": "Chat-ID",
                        "data_security": "Data Security",
                        "date_of_creation": "Date of creation",
                        "date_until":"Date (until)",
                        "day": "Day",
                        "decline": "Decline",
                        "decline_requests": "Decline requests",
                        "decline_requests_with_alternatives": "Decline requests and show alternative contact opportunities",
                        "delete_account": "Delete Account",
                        "delete_token": "Delete Token",
                        "descr_request": "Enter your request",
                        "developer_lnk": "For Developer",
                        "docu": "Instructions",
                        "done": "Ok",
                        "domain_error": "The domain format is incorrect. It should be in the format \"example.com\".",
                        "domain_success": "Domain okay.",
                        "edit_password": "Change Password",
                        "open_profile": "Open Profile",
                        "edit_profile": "Edit Profile",
                        "edit_username": "Change Username",
                        "email": "Email",
                        "email_error": "Email already in use",
                        "email_notification": "Email Notification",
                        "email_success": "Email available",
                        "eng": "English",
                        "EXPERT": "Expert",
                        "CUSTOMER": "User",
                        "expert_not_available": "No expert available",
                        "expert_not_found": "Expert no found",
                        "expert_offline": "Expert currently busy",
                        "expired": "expired",
                        "faq": "FAQ",
                        "first_name": "First Name",
                        "from": "From",
                        "generate": "Generate",
                        "generate_token": "Generate Token",
                        "ger": "German",
                        "goodbye": "Goodbye",
                        "guest_access": "Guest access",
                        "guest_acc_info": "Go directly to the chat",
                        "how_to": "How does ProDuck work?",
                        "iban": "IBAN",
                        "illegal_domain": "Illegal domain",
                        "image_link": "Image Link",
                        "image_link_tagged": "Affiliate-Image Link",
                        "jump_chat_expl": "By clicking the button below, you start an independent <a href=\"https://www.jump.chat/c/\" target=\"_blank\" class=\"nofollow noopener\" >Jump Chat</a>, which is particularly suitable if your chat partner wants to join the chat via mobile phone and does not want to install an app.",
                        "widget": "Widget",
                        "widget_blank": "Widget without text",
                        "widget_image": "Only Image",
                        "widget_large": "Widget large",
                        "imprint": "Imprint",
                        "imprint_link": "Links & Imprint",
                        "income": "Income",
                        "index_page": "Home",
                        "insert_mode": "Insert Message",
                        "insert_mode_end": "Stop Inserting",
                        "language": "Language",
                        "last_name": "Last Name",
                        "later": "Postpone",
                        "leave_email": "Leave email",
                        "leave_form": "Leave url of contact form",
                        "leave_number": "Leave number",
                        "link_generator": "Link Generator",
                        "link_invalid": "Invalid input",
                        "link_optional": "Link (optional)",
                        "login": "Login",
                        "login_with": "Sign in with ",
                        "login_info": "Go directly to the login",
                        "login2": "Go to Login",
                        "logout": "Logout",
                        "maximum_no_chats": "You've reached the maximum number of chats.",
                        "membership": "Member since",
                        "message": "Message",
                        "message_delete": "Delete message",
                        "message_history_info": "Do you want to delete this message from your chat history",
                        "munich": "Munich",
                        "new_chat_alert": "New Chat Alert",
                        "new_chat_notification": "New Chat Notification",
                        "new_password": "New password",
                        "new_request": "New request",
                        "new_username": "New username",
                        "newsletter_opt_in": "Click here to receive the latest news, product updates, event announcements, and other communications from ProDuck. You can unsubscribe anytime.",
                        "quack_activated": "Quack activated",
                        "quacks_activated": "Quacks activated",
                        "quack_deactivated": "Quack deactivated",
                        "quacks_tutorial_link": "How to create a new chat dialogue?",
                        "no": "No",
                        "no_chat": "You have no active chats",
                        "no_chats": "Found no chats",
                        "no_chats_yet": "No chats available yet",
                        "no_data_available": "Publish articles, chats or questions to activate your statistics",
                        "no_data_retrieved": "No Data could be retrieved from ProDuck. Please try again later",
                        "no_requests": "You have no open chat requests",
                        "no_service": "Service not available",
                        "no_token": "No partner domains with Quack tokens have been found so far. Add new domains in the format \"example.com\" here and save them using the \"{{buttonName}}\" button.",
                        "no_domain": "No domain found. It is possible that no external partner site has been added to your account yet. You can enter these in the settings under the \"External Sites\" menu item.",
                        "notifications": "Notifications",
                        "offer": "Product",
                        "operating_hours": "Consulting Hours:",
                        "or": "or",
                        "original_version": "Original version",
                        "password": "Password",
                        "password_error": "Password does not match the requirements (at least 8 characters, lower and upper case, 1 special character, 1 number)",
                        "password_error2": "Passwords do not match",
                        "password_forgotten": "Password forgotten",
                        "password_pattern": "Must contain at least 8 characters, lower and upper case, 1 special character, 1 number",
                        "password_success": "Password correct",
                        "password_success2": "Passwords do match",
                        "password_username_error": "Your email or password is wrong.",
                        "payment": "Payment",
                        "payment_details": "Payment Details",
                        "paymentHint": "To make sure that you get paid, please provide your address. We need this to send you important information about your account.",
                        "paypal_mandatory": "Paypal Client ID*",
                        "phone": "Phone",
                        "phone_string": "+49 174 3500996",
                        "placeholder_chat": "Text a message",
                        "prev_password": "Current password",
                        "press": "Press",
                        "pricing": "pricing",
                        "product_provider": "Product Provider",
                        "product_ref": "open product website",
                        "product": "Product",
                        "product_edit": "Edit Product",
                        "product_chat": "Produktchat",
                        "product_category_mandatory": "Product-category*",
                        "product_name": "Product name",
                        "product_link": "Product link",
                        "product_link_or_asin": "Product link or ASIN",
                        "product_test": "Product Test",
                        "profession": "Profession",
                        "profile": "Profile",
                        "profile_instruction": "Please fill out the following fields to create a profile.<br/><br/>You can add additional text and media blocks where you can, for example, showcase products or services. (<a href=\"/profile/2127\" target=\"_blank\">View example profile</a>)<br/><br/>You can also customize your text with selected HTML codes. An overview of all permissible HTML codes can be found <a href=\"/docu/profile.html#beautify-profile\" target=\"_blank\">here</a>.<br/><br/>Finally, save the result by clicking \"Save Profile\" below.",
                        "PROVIDER": "Provider",
                        "provider_upgrade_intro": "Become <b>provider</b> to get the following features:",
                        "provider_upgrade_feature1": "Let verified authors write articles for you.",
                        "provider_upgrade_feature2": "Create product- and service-offers for your customers.",
                        "provider_upgrade_feature3": "Sell products and services directly in the chat via Paypal.",
                        "provider_upgrade_feature4": "Form teams with your own employees or ProDuck experts for your customer chat support.",
                        "publish_quack": "Publish your dialogue now online, to support further customers!",
                        "publish_quack_btn": "Publish",
                        "publish_quack_title": "Publish or unpublish the selected Quack",
                        "quack_error": "The server responded with an error. Please make sure that you have set a title and a maximum of 5 tags (in total not more than 495 characters) and try again. Otherwise please contact our customer service via the <a href=\"/contact.html\">contact form.</a>",
                        "quack_published": "Your quack has now been published successfully. You can close this chat window now.",
                        "question": "Question",
                        "questions_shortcut": "Further Questions",
                        "quit_chat": "Finish Chat",
                        "rating": "Rating",
                        "recent_quacks": "Recent Quacks",
                        "recent_topics": "Recent Chats",
                        "recommend": "Thank you for using the service of ProDuck. We would be delighted if you recommend our service to your friends, fellows and other shops.",
                        "recommendation": "Recommendation",
                        "register": "Sign up",
                        "register_headline_cust": "As Customer",
                        "register_headline_exp": "As Expert or Product Provider",
                        "register_slogan_cust": "Our product experts provide you with free advise and support to facilitate your buying decision. <br/><span>Sign Up Free</span>",
                        "register_slogan_exp": "Support people in making the right buying decision and receive generous commission. <br/><span>Sign Up Free</span>",
                        "register_notification_1": "Please verify: Your username can include letters, numbers and hyphens. Hyphens must not stand at the beginning, end or directly one after the other. Further special characters and \"Ducky\" as part of the Name are not allowed. If your problems persist, please contact us under info@monstec.de.",
                        "register_notification_2": "Please verify if you have chosen an account role in the selection field \"Account\". If your problems persist, please contact us under info@monstec.de.",
                        "repeat_password": "Repeat new password",
                        "request_accepted_by_sibling": "You have already accepted the request in another window or on another device.",
                        "request_activation": "Request activation",
                        "request_preempted": "Another expert has already accepted the request.",
                        "request_cancelled": "The user has aborted the chat request.",
                        "request_declined": "Your search request has not been accepted by any expert. We recommend you to try it again later",
                        "request_declined_by_sibling": "You have already declined the request in another window or on another device.",
                        "request_info": "You have a new chat request:",
                        "request_topic_application": "Your application letter",
                        "request_process_err": "The request could not be processed by the server. The page will be reloaded.",
                        "request_remove": "Remove request",
                        "request_remove_all": "Remove expired requests",
                        "request_remove_all_enquiry": "Do you really want to permanently remove all expired requests?",
                        "request_remove_enquiry": "Do you really want to permanently remove the request?",
                        "request_topic": "Your request",
                        "request_to_self": "You can not issue a request to yourself while logged in. Try another browser or the private mode.",
                        "sales_last_60": "Sales of last 60 days",
                        "search": "Search",
                        "search_btn": "Start chat with expert",
                        "search_shop_id": "Search Shop-ID",
                        "send": "Send",
                        "service_not_reachable": "Service not reachable",
                        "share": "Share",
                        "share_link": "Share Link",
                        "share_channel": "My Produck.de-Channel:",
                        "shortcuts": "Chat Shortcuts",
                        "slogan": "Product Advisement as SaaS for Online Shops",
                        "start_chat": "Start Chat",
                        "status": "Status: ",
                        "stay_here": "Stay here",
                        "msg_status": ["sending","Failure while sending","sent","received","read"],
                        "store_quack": "You can now publish this chat. Choose a suitable headline, the language and the target site. Finally, confirm with \"Publish\". You can also edit and deactivate this chat later on.",
                        "store_requests": "Store requests in my request list",
                        "store_requests_with_alternatives": "Store requests in my request list and show alternative contact opportunities.",
                        "support": "Support",
                        "table_of_content": "Table of Content",
                        "terms": "Terms and Conditions",
                        "terms_opt_in": "By clicking the button, you agree to ProDuck's <a href=\"/data-sec.html\" target=\"_blank\" tabindex=\"-1\">privacy policy</a> including the processing of your data to answer your request.",
                        "terms_opt_in_author": "By clicking \"{{confirmBtnText}}\", you confirm that you have read and understood ProDuck's <a href=\"/agb-autoren.html\" target=\"_blank\" tabindex=\"-1\">terms and condition for verified authors</a> and agree to them.",
                        "text_field_validation": "The field must not include special characters except for hyphens, dots and whitespaces.",
                        "tidy_up_list": "Tidy up list",
                        "thanks": "Thank",
                        "thank_you": "Thank you ",
                        "to_chat": "To Chat",
                        "to_cust_service": "Customer Service",
                        "topic": "Topic",
                        "try_again": "Submit new request",
                        "try_later": "Your contact partner is currently busy",
                        "tutorials": "Tutorials",
                        "under_construction": "This area is currently under construction",
                        "update_password": "Update Password",
                        "update_username": "Update Username",
                        "upgrade": "Upgrade",
                        "upgrade_account_author": "Upgrade to author",
                        "upgrade_account_provider": "Upgrade to provider",
                        "upgrade_account_successful": "Account upgrade has been successful. Please re-login!",
                        "upgrade_modal_headline": "Upgrade account",
                        "upgrade_modal_text": "In order to use this function, you have to upgrade your account. You can do that in settings >> account.",
                        "username": "Username",
                        "username_error": "Username already in use",
                        "username_pattern": "The username must not include umlauts, special characters or whitespaces",
                        "username_success": "Username available",
                        "wait": "Please stay in the chat",
                        "wait_shortcut": "Please wait",
                        "wait_over_shortcut": "Thanks for waiting",
                        "website": "Website",
                        "website_cv_application": "Website or CV-link",
                        "website_mandatory": "Website*",
                        "weekdays":["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
                        "weekdaysAbbrev": ["S","M","T","W","T","F","S"],
                        "weekdaysShort": ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
                        "welcome": "Welcome",
                        "welcome2": "Welcome with Question",
                        "writing": "writing...",
                        "xpert": "Expert",
                        "yes_delete": "Yes, delete",
                        "registration": "Registration",
                        "registration_successful": "Registration successful",
                        "congrats": "Congratulation",
                        "registration_succeeded": "Your registration was successful.",
                        "registration_instruction": "You can now complete your registration by verifying the link sent to your email address. Afterwards, you can sign in by using your email address and password and will get access to all functions of our platform then.",
                        "chat_discuss": "Discuss in Chat",
                        "chat_discuss_txt": "Discuss",
                        "buy": "Buy",
                        "chat_to_offer": "Go to offer",
                        "contact_expert": "Experte kontaktieren",
                        "contact_expert": "Contact Expert",
                        "send_partner": "Send to Chatpartner",
                        "save": "Save",
                        "descr_self": "Describe yourself...",
                        "descr_prod": "Short description of the product",
                        "copy": "Copy",
                        "hello": "Hello",
                        "nickname": "Nickname:",
                        "expertise": "Sepcialist / Expert for ...",
                        "title": "Degree",
                        "section_title": "Section Title for the Navifation (1 word)",
                        "headline": "Headline",
                        "pic_url": "Portrait url",
                        "media_url": "Picture-Url",
                        "background_pic_url": "Background picture url",
                        "choose": "Choose action",
                        "selectChat": "Please select at least one chat, before choosing an action.",
                        "selectAction": "Please choose an action.",
                        "selectChatNAction": "Please choose an action and select at least one chat before publishing.",
                        "time": "Time",
                        "video_chat_start": "Start Video Chat",
                        "vet_chat": "Online Consultation",
                        "views": "Views",
                        "words": "Words"
                    },
                    "icon": {
                        "account_circle": "<i class=\"material-icons\">account_circle</i>",
                        "add_alert": "<i class=\"material-icons\">add_alert</i>",
                        "add": "<i class=\"material-icons\">add</i>",
                        "chat": "<i class=\"material-icons\">chat</i>",
                        "chat_bubble_outline": "<i class=\"material-icons md-light\">chat_bubble_outline</i>",
                        "chrome_reader_mode": "<i class=\"material-icons\">chrome_reader_mode</i>",
                        "checkmark": "<i class=\"material-icons\">check_circle</i>",
                        "dashboard": "<i class=\"material-icons md-light\">dashboard</i>",
                        "description": "<i class=\"material-icons md-light\">description</i>",
                        "edit": "<i class=\"material-icons md-light\">edit</i>",
                        "email": "<i class=\"material-icons\">email</i>",
                        "euro_symbol": "<i class=\"material-icons\">euro_symbol</i>",
                        "exit_to_app": "<i class=\"material-icons\">exit_to_app</i>",
                        "help": "<i class=\"material-icons\">help</i>",
                        "key": "<i class=\"material-icons\">vpn_key</i>",
                        "location": "<i class=\"material-icons\">location_on</i>",
                        "lock": "<i class=\"material-icons\">lock</i>",
                        "link": "<i class=\"material-icons\">link</i>",
                        "notifications": "<i class=\"material-icons\">notifications</i>",
                        "phone": "<i class=\"material-icons\">phone</i>",
                        "settings": "<i class=\"material-icons md-light\">settings</i>",
                        "search": "<i class=\"material-icons md-light\">search</i>",
                        "stars": "<i class=\"material-icons md-light\">stars</i>",
                        "video_chat": "<i class=\"material-icons\">videocam</i>",
                        "video_chat_left": "<i class=\"material-icons left\">videocam</i>"
                    },
                    "indexpage": {
                        "affiliate_tag_steps": "Add your Amazon affiliate tag to your ProDuck profile settings and add the new website in your Amazon PartnerNet account. You will be able to see transactions in your Amazon account. Additionally, we recommend monitoring the links on external sites to ensure that other scripts do not overwrite your affiliate tag.",
                        "ai_market_transformation": "The rise of AI has transformed the market landscape. Search engines now increasingly prioritize authority sites and user-generated content, making success for informational sites more challenging. AI-generated responses keep customers on search engines, reducing visits to third-party websites. Many blogs are feeling the impact, and this is likely just the beginning.",
                        "avoid_background_tasks": "Forget About the Distracting Background Tasks",
                        "benefits_for_hosts": "What benefits do blog hosts receive?",
                        "benefits_summary": "Benefits in a Nutshell",
                        "beta_phase_started": "Beta Phase Started: Limited Free Access",
                        "blog_host_benefits": "Blog hosts benefit too – with a surge in traffic, enhanced cross-site conversions, and increased ad revenue. Elevate your marketing game with ProDuck!",
                        "content_cooperation": "Content cooperation between blogs is nothing new. However, hosting your content on external blogs carries the risk of losing control over it. There have been instances where authors were banned by site admins, losing access to their content and revenue. Proving authorship and removing content from foreign sites can be a tedious process.",
                        "content_hub_solution": "ProDuck's Content Hub offers a quick and effortless solution. With just a few clicks, you can shift your content to resilient blogs that thrive amidst the changes.",
                        "content_still_hosted": "If this occurs, your content can still be temporarily hosted on ProDuck.de until a new blog host is found.",
                        "content_system_control": "With ProDuck's Content System, you remain the sole admin of your content. You can easily remove your content from any platform. While site owners have the right to depublish content, so can you. Maintain control and security with ProDuck.",
                        "content_tailored_audience": "In today's digital landscape, a successful site goes beyond plain text, offering a rich multimedia experience. ProDuck enables you to enhance your content with product widgets, videos and images, and provides diverse content types such as articles, chats, Q&As and news. Deliver your message effectively and leverage rich snippets in search engines to boost your content's visibility.",
                        "create_and_manage_content": "Step 2: Create and manage your first content",
                        "create_content_today": "Create Content Today",
                        "diverse_content_creation": "Create AI-supported <u>diverse content</u> such as articles, chats, Q&As, news, or deals to inform your followers as they like.",
                        "does_produck_own_copyright": "Does ProDuck.de own the copyright to my content, and can they take it from me?",
                        "effortless_content_creation": "Not wanting to be tied to a single niche? ProDuck makes it effortless to write top-ranking reviews across various categories, whether it's a bicycle, a coffee machine, or a robot vacuum. Find the perfect niche blogs to host your content, ensuring maximum visibility and engagement. By partnering with established blogs, you’ll see your sales soar.",
                        "ensure_affiliate_tag": "How can I ensure my affiliate tag is used on external sites?",
                        "exclusive_offer": "Exclusive Offer Ends In:",
                        "expand_content_reach": "Expand Your Content's Reach",
                        "export_import_inconvenient": "Exporting and importing content gives the new blog owner control over your content and is much more cumbersome than transferring a post with just two clicks using ProDuck.",
                        "further_questions": "You've got further questions?",
                        "find_wordpress_partner": "Step 3: Find a suitable WordPress partner blog and share your content externally or publish immediately on ProDuck.",
                        "flexibility": "Flexibility",
                        "flexibility_equals_success": "Flexibility &#61; Success",
                        "focus_on_content": "Focus on Content",
                        "focus_on_content_only": "Focus on Your Content",
                        "full_control": "Full Control",
                        "get_full_access_now": "Get Full Access Now",
                        "google_penalties": "To our knowledge, Google's penalties for bad content are not automatically transferred when moving to a new blog. However, Google may re-evaluate the content, potentially reinstating the penalty and affecting the new blog's ranking. Authors and blog hosts should ensure they offer high-quality content and filter out any potentially harmful material.",
                        "how_to_start": "How To Start",
                        "included_services": "With ProDuck, many of these services are included by default. While we can't replace every specialized plugin, we strive to offer an efficient setup that maximizes your investment.",
                        "integrated_features": "Domains, webspace, plugins, analytics tools, etc. – these can add up to a significant expense. Eliminate the clutter of subscriptions and save money by using <u>ProDuck's integrated features</u>.",
                        "limited_free_access": "Be among the first 1,000 users to enjoy free access! Sign up now to unlock the full potential of your content with ProDuck. Take advantage of this limited-time offer and join the growing community of successful content creators. Don't miss out—act now!",
                        "maximize_output": "Maximize Your Output",
                        "more": "More...",
                        "more_info": "More",
                        "more_reach": "More Reach",
                        "multi_content_management": "Multi-Content Management",
                        "multi_content_types": "Multi-Content Types",
                        "next_steps": "<strong>Next Steps:</strong>",
                        "new_articles": "New Articles",
                        "outsource_without_losing_control": "Outsource your content <u>without losing control</u>. Blog owners hosting your texts can't claim or modify your content. They can, however, accept or decline your submissions and request changes through internal messaging or chat.",
                        "performance_drop": "Has your blog's performance dropped, and you want to be prepared the next time? Stay flexible in times of rapid change. As a ProDuck blogger, your <u>content can be moved</u> to another site with just a few clicks or published directly on ProDuck in the meantime.",
                        "plugin_free": "At the moment, the WordPress plugin is completely free.",
                        "platform_management": "We manage and <u>develop the platform for you</u>, freeing you from tedious IT and SEO tasks. Take advantage of features like markup language, rich snippets, consent forms, product widgets for your own or Amazon products, and comprehensive analytics.",
                        "powerful_features": "Our platform offers a wealth of powerful features, many integrated by default and others customizable to suit your needs. For example, easily embed Amazon Widgets in your texts with your own affiliate tags.",
                        "produck_retain_rights": "Similar to platforms like Reddit or YouTube, ProDuck retains the right to reuse the content, but it would be detrimental and against the service's interests to claim ownership or remove it from you.",
                        "publish_in_various_niches": "This further allows you to publish in various niches, languages, and styles simultaneously, without the need to manage multiple blogs.",
                        "cost_of_wordpress_plugin": "What are the costs of the WordPress plugin?",
                        "followers_notified": "Are followers notified about both internal and external publications?",
                        "followers_notified_details": "Yes, your followers are notified about all your publications, whether internal or external, at regular intervals.",
                        "hosts_benefit": "Blog hosts benefit from increased traffic, which leads to higher ad revenue, more followers, and enhanced cross-selling opportunities.",
                        "what_happens_if_host_removes_content": "What happens if the blog host removes my content?",
                        "what_happens_if_submission_declined": "What happens if my content submission is declined by the blog host?",
                        "why_not_export_import": "Why not simply export and import content between WordPress blogs?",
                        "ready_to_get_started": "Ready to Get Started?",
                        "redirect_penalties": "Does transferring content with a 301 redirect inherit Google penalties?",
                        "reposition_content": "Easily <u>reposition underperforming content</u> to more promising locations. Liberate your content from being tied to a single site. Our Content Distribution Service allows you to place your content on the most suitable and best performing blogs.",
                        "run_a_blog": "Running a blog involves countless minor tasks that can steal your time and focus. Like YouTube or Medium, ProDuck Content Hub liberates you from these distractions, allowing you to concentrate on what truly matters – your content.",
                        "save_money": "Save Money",
                        "save_money_blog": "Save Your Money",
                        "secure_your_content": "Secure Your Content: Keeping Control of your intellectual Property",
                        "sign_up_for_free": "Sign Up for Free",
                        "sign_up_step": "Step 1: Sign up for free and create your account",
                        "start_free": "Start for Free",
                        "stay_ahead_with_plan_b": "Stay Ahead with a Plan B",
                        "stay_ahead_with_updates": "At ProDuck, we stay ahead of the curve. Our system continuously evolves to adapt to new trends, with frequent feature updates to ensure you always have the best tools at your disposal.",
                        "submission_declined": "If your content submission is declined, you will be notified. You can then refine your content and submit it again or search for another suitable blog.",
                        "tailored_content": "Content Tailored to Your Audience",
                        "track_performance": "Step 4: Track performance and adjust as needed",
                        "trending_articles": "Trending Articles",
                        "unanswered_questions": "Unanswered Questions",
                        "wordpress_blog_costs": "Anyone who has hosted a WordPress blog knows that as your blog grows, so do the costs for necessary extensions and upgrades.",
                        "your_content_your_decision": "Your Content, Your Decision"
                    },
                    "toasts": {
                        "absent_mode_saved": "absent mode saved",
                        "chat_activated": "Chat activated",
                        "chat_deactivated": "Chat deactivated",
                        "check_input": "Please check your entry",
                        "chips": "+tag (max 5) and confirm with return",
                        "data_missing": "Data missing",
                        "data_invalid": "Data invalid",
                        "data_updated": "Data updated",
                        "data_update_failed": "Data update failed",
                        "delete_article_accomplished": "Artikel gelöscht",                        
                        "times_twisted": "Please verify: Each start time must begin before the corresponding end time.",
                        "error": "An error has occured.",
                        "language_updated": "Language updated",
                        "message_deleted": "Message deleted",
                        "message_updated": "Message updated",
                        "no_access": "No access",
                        "no_data_found": "No data found",
                        "password_change_fail": "Password change failed. Please, try again.",
                        "password_change_success": "Password changed successfully",
                        "password_incorrect": "Current password incorrect",
                        "reject": "Reject",
                        "rejected": "Rejected",
                        "tags_updated": "Tags updated",
                        "title_updated": "Title updated",
                        "too_many_bytes": "File too big (>10MB)",
                        "unsupported_filetype": "File type not supported",
                        "upload_failed": "Upload failed",
                        "username_change_fail": "Username change failed. Please, try again",
                        "username_change_success": "Username changed successfully",
                        "error_msg": {
                          "error_occurred": "Oops - Something went wrong!",
                          "try_again": "Please try again to {{action}}. Use the following link to return.",
                          "reset_password": "reset your password",
                          "login": "log in",
                          "register": "register"
                        }
                    },
                    "metadata": {
                        "profile_generator_title": "Profile generator for creating a personal profile on ProDuck.",
                        "profile_generator_description": "User can create their own profile on ProDuck and publish it."
                    },
                    "modals": {
                        "chat_left": " has left the chat.",
                        "chat_left_sibling_session": "You have left the chat.",
                        "session_ended": "Your Session has expired. Please login again."
                    },
                    "html": {
                        "affiliate_note": "<p id=\"affiliate-note\">* Please note that links on this page may be links to affiliate partners. For purchases made through one of these links, we receive a commission (if you accept the affiliate partner's marketing cookies). This incurs no additional costs to you, but you support our service (<a href=\"/docu/general.html#affiliate-links\" rel=\"nofollow\" target=\"_blank\">learn more</a>). Prices, delivery conditions, and availability correspond to the stated status (date/time) and are subject to change at any time. Information on our site may differ from those on the partner sites. For the purchase of a given product, the price and availability information displayed on the relevant website(s) (e.g., Amazon) at the time of purchase applies. Certain content displayed on this website is sourced from Amazon. This content is provided 'as is' and may be changed or removed at any time.</p>",
                        "contact_proposal1": "You can reach him/her via email or phone at",
                        "contact_proposal2": "You can reach him/her via email at",
                        "contact_proposal3": "You can reach him/her via phone at",
                        "contact_proposal4": "Please try again later.",
                        "upgrade_account_paypal_hint": "To be able to directly sell products or consultation services via Paypal, you will need a Paypal business account and a client-ID. You can save the latter in settings >> payment, so that all transactions can be processed. You can find the Paypal client-ID here: <a href=\"https://developer.paypal.com/docs/archive/checkout/integrate/#replace-sandbox-credentials-with-live-credentials\" target=\"_blank\">Paypal Live Credentials</a> Step 1-6 (approx. 2 minutes to complete).",
                        "usage_info": "By using this service you agree to the <a href=\"/agb.html\" target=\"_blank\" rel=\"noopener noreferrer\">terms of use</a> and the <a href=\"/data-sec.html\" target=\"_blank\" rel=\"noopener noreferrer\">data protection regulations</a> of ProDuck.",
                        "request_submitted": "I relayed your request&nbsp;<b>{{topic}}</b>&nbsp;to our experts.",
                        "chat_name_request": "What do you like to be called by the expert:<input class=\"user-conversation-name\" placeholder=\"Name\"></input>",
                        "login_invitation": "Please <a href=\"https://www.produck.de/signup.html\">register</a> or <a href=\"https://www.produck.de/login.html\">login</a>, in order to use all features of ProDuck."
                    },
                    "profilepage": {
                      "account_deleted_successfully": "Your account was successfully deleted",
                      "account_deletion_failed": "Your account could not be deleted. Please check your internet connection and try again.",
                      "chat_start": "Start chat",
                      "create_own_profile": "Create own profile",
                      "data_update_failed": "Data could not be updated. Error message: ",
                      "expert": "Expert",
                      "heading_1": "- Heading 1 -",
                      "heading_2": "- Heading 2 -",
                      "heading_3": "- Heading 3 -",
                      "how_to_write_article_for_domain": "How to write an article for {{domain}}",
                      "media_block_add": "Add media block",
                      "preview": "Preview",
                      "profile": "Profile",
                      "profile_overview": "Profile overview",
                      "profile_page_of": "Profile page of ",
                      "profile_save": "Save profile",
                      "register": "Sign up",
                      "suggest_article_to_domain": "Suggest an article to {{domain}}",
                      "text_block_add": "Add text block",
                      "title": "Title"
                    },
                    "order": {
                      "back_to_chat": "Back to chat",
                        "back_to_cart": "Back to cart",
                        "your_order": "Your Order",
                        "cart_overview": "Your Cart",
                        "update": "update",
                        "remove_all": "remove all",
                        "checkout": "Proceed to Checkout",
                        "order_and_delivery": "Your Invoice- und Delivery Address",
                        "first_name": "First Name*",
                        "last_name": "Last Name*",
                        "street": "Street*",
                        "city": "City*",
                        "zip_code": "Zip Code*",
                        "house_no": "House number*",
                        "info_characters": "",
                        "info_error_characters": "Please just use letters.",
                        "info_error_no": "Format: 30 or 30a",
                        "info_error_no2": "Please use 5 numbers exactly.",
                        "email": "Email*",
                        "phone": "Phone",
                        "terms": "I agree to the <a href=\"/agb.html\" target=\"_blank\" tabindex=\"-1\">terms and conditions</a> and the <a href=\"/data-sec.html\" target=\"_blank\" tabindex=\"-1\">privacy policy</a> of Produck.de.",
                        "confirm_data": "Daten bestätigen",
                        "incl_tax": "*All prices include tax (VAT). Product available - Dispatch within 1-3 days. The shipment is free.",
                        "pay_adv": "Pay in Advance",
                        "check_and_order": "Everything correct?",
                        "verify_data": "Please verify your order and confirm by clicking \"Buy now\". If you want to edit your order, go to \"Edit Order\" to get back to the cart.",
                        "submit_order": "Buy Now",
                        "shipment": "Shipment Costs (Product available - Dispatch within 1-3 days. The shipment is free.)",
                        "edit_order": "Edit Order",
                        "total": "Total price (incl. VAT)",
                        "buy_notification": "I intend to buy this article",
                        "buy_confirmation_self": "Thank you. The expert has received your payment.",
                        "buy_confirmation_partner": "The customer has successfully paid this article: ",
                        "cart_value": "Cart Price",
                        "really_remove1": "Do you want to remove this item from your cart?",
                        "really_remove2": "By emptying your cart, all items are irrevocably deleted. Do you want to continue?",
                        "amount": "Please choose the desired amount",
                        "advisor_note1": "You get advised by ",
                        "advisor_note2": ". Contact Link: https://produck.de/chat.html?cid=",
                        "thanks_for_buy": "Produck.de says thank you for your order.",
                        "request": "Request"
                      },
                      "shariff": {
                          "mailBody1": "Hi, I want to recommend the following service to you {url}. There you can get advise and support to a various number of products. Check it out!",
                          "mailBody2": "Hi, you can reach me via the following link",
                          "shareQuack": "My recommendation",
                          "title": "ProDuck - The expert among the online portals"
                      },
                },
            },
        },
    },
        function (err, t) {
            // initialized and ready to go!
            if (err) return console.error(err);

            jqueryI18next.init(i18next, $, {
              tName: 't', // --> appends $.t = i18next.t
              i18nName: 'i18n', // --> appends $.i18n = i18next
              handleName: 'localize', // --> appends $(selector).localize(opts);
              selectorAttr: 'data-i18n', // selector for translating elements
              targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if different than itself)
              optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
              useOptionsAttr: true, // see optionsAttr
              parseDefaultValueFromContent: true // parses default values from content ele.val or ele.text
            });
            console.log("i18next initialized successfully");
        }
    );

export default i18next;
