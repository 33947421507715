/* global monstecLib */
/* global M */
import ObjectForm from '../common/objectform';
import i18next from "../localisation.js";

/**
 * Input form for creating article requests.
 *
 * Implement 'sendAction' for an instance of this class to define the behaviour of the
 * current ArticleRequestForm object when clicking the send button.
 *
 * Implement 'onSendingSuccessful' to react on the successful delivery of the article
 * request to the destination.
 */
export default class ArticleRequestForm extends ObjectForm {
    /**
     * Construct a new form that can then be attached to an element.
     */
    constructor() {
        super();
        this.MIN_CHARACTER_COUNT_SUMMARY = 10;
        this.MAX_CHARACTER_COUNT_SUMMARY = 5000;

        this.authenticator = monstecLib.produckContext.authenticator;
        this.chatService = monstecLib.produckContext.chatService;

        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find('.chip').last()[0].childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-');
        }

        this.chipsOptions = {
            'placeholder': i18next.t('toasts.chips'),
            'limit': 5,
            'onChipAdd': function(chips) {addTag(chips);}
        };

        this.log = new monstecLib.Log(1);
    }

    /**
     * Attaches the current instance to a specific element. That element must include
     * the article-request-form-nunjucks-template and should ideally be a div.
     *
     * @param {string} htmlId identifies the container element that includes the article
     *                        request form
     */
    attachTo(htmlId) {
        const instance = this;
        instance.htmlId = htmlId;

        let container = $('#' + htmlId);
        instance.container = container;

        // Localise form labels before initialising the input elements. This is necessary
        // so that MaterializeCSS actually uses the resolved localisation properties for
        // the select-elements for example.
        instance.container.localize();

        let articleRequestFormCloser = container.find('#articleRequestFormCloser');
        articleRequestFormCloser.on('click', () => instance.container.removeClass('active'));

        let titleInput =  container.find('.js-article-request-title');
        titleInput.attr('id', htmlId + 'TitleInput');
        titleInput.siblings('label').attr('for', titleInput.attr('id'));
        instance.titleInput = titleInput;

        // init language selection
        let languageSelect = this.container.find('.js-language-select');
        instance.languageSelect = languageSelect;
        instance.languageSelectM = instance._initLanguageSelect(languageSelect);

        this.languageSelectM

        // initialise editing area for content summary
        let summaryTextarea = this.container.find('.js-summary-textarea');
        summaryTextarea.attr('id', htmlId + 'SummaryInput');
        instance.summaryTextarea = summaryTextarea;

        instance.utils.addCharacterCounter(summaryTextarea, undefined, instance.MIN_CHARACTER_COUNT_SUMMARY, instance.MAX_CHARACTER_COUNT_SUMMARY, true);
        M.textareaAutoResize(summaryTextarea);

        // init tag module
        let tags = this.container.find('.chips');
        tags.attr('id', htmlId + 'TagsInput');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];

        // init template selection
        let templateSearchField = this.container.find('.js-article-template-select');
        templateSearchField.attr('id', htmlId + 'TemplateInput');
        templateSearchField.siblings('label').attr('for', templateSearchField.attr('id'));

        instance.chatService.getArticleTemplateChoices('').then(function(choiceArray) {
            let autocompleteData = {};
            choiceArray.forEach(function(choice) {
                autocompleteData[choice.name] = null;
            });

            var templateSearchFieldOptions = {
                data: autocompleteData,
                'minLength': 3
            };

            instance.templateSearchField = templateSearchField;
            instance.templateSearchFieldM = M.Autocomplete.init(templateSearchField, templateSearchFieldOptions)[0];
        });

        // initalise word-count fields
        let wordCountMinInput = container.find('.js-word-count-min-input');
        wordCountMinInput.attr('id', htmlId + 'WordCountMinInput');
        wordCountMinInput.siblings('label').attr('for', wordCountMinInput.attr('id'));
        instance.wordCountMinInput = wordCountMinInput;

        let wordCountMaxInput = container.find('.js-word-count-max-input');
        wordCountMaxInput.attr('id', htmlId + 'WordCountMaxInput');
        wordCountMaxInput.siblings('label').attr('for', wordCountMaxInput.attr('id'));
        instance.wordCountMaxInput = wordCountMaxInput;

        // initialise editing area for content summary
        let instructionsTextarea = this.container.find('.js-instructions-textarea');
        instructionsTextarea.attr('id', htmlId + 'InstructionsInput');
        instance.instructionsTextarea = instructionsTextarea;
        M.textareaAutoResize(instructionsTextarea);

        instance.utils.addWswgBar(instructionsTextarea, {"htmlEle": languageSelect});

        // initialise sendButton
        let sendButton = this.container.find('.js-send-button');
        sendButton.attr('id', htmlId + 'SendButton');
        sendButton.on('click', function(e) {
            $(document).trigger("loader:on", ['', 'transparent', document.getElementById('targetArticle')]);
            instance._onSendButtonClicked(e);
        });
    }

    /**
     * Collects information from the input fields on the form to build an article request object.
     */
    _createObjectFromFields() {
        const instance = this;
        let articleRequest = {};

        try {
            articleRequest.title = instance.titleInput.val();
            articleRequest.language = instance.languageSelect.val();

            let summary = instance.summaryTextarea.val();
            if (!!summary && summary.length > 0) {
                articleRequest.summary = summary;
            }

            let instructions = instance.instructionsTextarea.val();
            if (!!instructions && instructions.length > 0) {
                articleRequest.instructions = instructions;
            }

            let tagsData = instance.tagsInput.chipsData;
            if (!!tagsData || Array.isArray(tagsData)) {
                let actualTags = tagsData
                    .filter(tuple => !!tuple.tag)
                    .map(tuple => tuple.tag.trim().replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, '-'));

                articleRequest.tags = actualTags;
            }

            articleRequest.wordCountMin = instance.wordCountMinInput.val();
            articleRequest.wordCountMax = instance.wordCountMaxInput.val();
            articleRequest.templateName = instance.templateSearchField.val();

        } catch (error) {
            instance.log.error('Could not extract values for article request: ', error);
        }

        instance.log.debug('Formed the following article request object from the input fields:', articleRequest);
        return articleRequest;
    }

    _emptyForm() {
        const instance = this;
        let inputElements = instance.container.find('input, textarea');

        inputElements.each(function(index, element) {
            let formElement = $(element);
            formElement.removeClass("valid invalid").css("height", "").val(null);
            M.updateTextFields();
        });

        // re-init selects
        if (instance.languageSelectM) {
            var lngOptions = {
                'classes': 'edit-language'
            };
            instance.languageSelectM.destroy();
            instance.languageSelect.val("de");
            instance.languageSelectM = M.FormSelect.init(instance.languageSelect, lngOptions)[0];
        }

        if (instance.templateSelectM) {
            var templateOptions = {
                'classes': 'edit-language'
            };
            instance.templateSelectM.destroy();
            instance.templateSelect.val("");
            instance.templateSelectM = M.FormSelect.init(instance.templateSelect, templateOptions)[0];
        }

        // re-initialise tags
        instance.tagsInput.destroy();
        let tags = $('#' + instance.htmlId + 'TagsInput');
        instance.tagsInput = M.Chips.init(tags, instance.chipsOptions)[0];
    }
}