/**
 * Handles the AI service interaction to send prompts and receive AI responses.
 */
export default class AIGenerator {
    constructor() {   
        this.i18next = monstecLib.produckContext.i18next;
        this.utils = monstecLib.produckContext.utils;
        this.chatService = monstecLib.produckContext.chatService;
        this.authenticator = monstecLib.produckContext.authenticator;
        this.cookie = monstecLib.produckContext.cookie;       
    
        this.log = new monstecLib.Log(1);

        // Check if the current user has the permission to upgrade his account. If he doesn't, redirect him.
        // instance.authenticator
        //     .checkPermission(
        //         instance.authenticator.permCat.WRITE_QUACK
        //     )
        //     .then(async function (hasPermission) {
        //         if (hasPermission) {
        //             await instance._initialise();
        //         } else {
        //             instance.log.debug(
        //                 "Authenticated user does not have the permission to write quacks."
        //             );
        //             window.location.href = "/";
        //         }
        //     })
        //     .catch(function (e) {
        //         // Page could not be initialised. This can happen for example when no user is authenticated at all.
        //         instance.log.debug("Initialisation failed.", e);
        //         window.location.href = "/";
        //     });
    }    

    async callChatGPTAPI(content, postId) {
        const instance = this;
        // console.log("ChatGPT Request: ", content, "PostId: ", postId);
        
        try {
            // Call the ChatGPT API and pass postId and userId as query parameters
            let userId = await instance.authenticator.getUser();
            const responseObj = await instance.chatService.getChatGPTResponse(content, postId, userId);
            
            if (responseObj &&  responseObj.response.length > 0) {
                console.log("Valid ChatGPT response received.");
                return responseObj.response;
            } else {
                console.warn("AI response is null. Using default content.");
                return null;
            }
            
        } catch (error) {
            // Check if the error message corresponds to the rate limit
            if (error.response && error.responseText.includes("Timeout")) {
                console.warn("Rate limit hit, notifying user about timeout.");
                M.toast({html: "Please wait a few seconds before sending the next AI request."});                
            } else {
                console.error("Failed to fetch AI response from Chat GPT API.", error);
                M.toast({html: instance.i18next.t('toasts.error_msg.error_occurred')});
            }

            return null;
        }
    }
}
