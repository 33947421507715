/* global monstecLib */
/* global M */

import AbstractQuackPage from './quackviewpage.js';

export default class ArticleQuackPage extends AbstractQuackPage {
    constructor() {
        super();

        this.i18next = monstecLib.i18next;
        this.log = new monstecLib.Log(1);
    }

    async initialise() {
        const instance = this;

        const articleId = $('#quackSingleChatContainer').attr('data-article-id');
        instance.log.debug('Page will be set up for article: ', articleId);

        instance.register = new monstecLib.Register(instance.authenticator);

        let articleCommentSection = new monstecLib.QuackCommentSection();
        articleCommentSection.attachTo('articleCommentSection');
        articleCommentSection.sendComment = function(referenceId, text) {
            return instance.chatService.addArticleComment(referenceId, text);
        };

        instance._tablifyDialogue(); //tablify before linkify

        let asyncLinkifyCaller = async function() {
            await instance._linkifyDialogue($('#quacklist-wrapper .question-hyperlink'));
            await instance._linkifyDialogue($('.quack-comment-section .quack-comment-text'));
        }

        asyncLinkifyCaller().then(() => {
            instance.lazyload.lazyloaderInit();
            instance.utils.initMaterializeInContentBlock()
        });
                
        instance.initBaseSiteMaterialize();
        instance._initQuacksScrollBar();
        instance._showHotOffersInAdBox();
        instance.utils.initShareContent();
        instance.utils.styleShariff();

        instance._initRatingControl(instance.authenticator.permCat.RATE_ARTICLE, articleId);
        instance._initReviewCapabilites(instance.authenticator.permCat.REVIEW_ARTICLES);

        instance.cookie.setCookieOkCookies();
        instance.register.initSignInForm(false, instance.cookie, false, true, ['#raise-new-question-bottom', '#answer-questions-bottom']);   
        instance._showInternalAds();
        instance._adjustCarouselHeight();
        $('[data-i18n]').localize();
    }


    /**
     * Initialises the control at the end of the page that enables a user to rate the
     * current quack or change a previous rating.
     */
    _initRatingControl(permission, articleId) {
        const instance = this;
        instance.ratingControl = new monstecLib.RatingControl(permission, true);
        instance.log.debug('Initialising article rating control.');

        instance.ratingControl.getCurrentRating = async function() {
            try {
                if (!await instance.authenticator.checkPermission(instance.authenticator.permCat.RATE_ARTICLE)) {
                    instance.log.debug('No permission RATE_ARTICLE!');
                    return undefined;
                }
            } catch (e) {
                instance.log.debug('Permission check for RATE_ARTICLE failed!');
                return undefined;
            }

            return await instance.chatService.getArticleRating(articleId);
        };

        instance.ratingControl.onStarClicked = async function(value) {
            try {
                let result = await instance.chatService.addArticleRating(articleId, value);
                instance.ratingControl.setRating(result.value);
                $('#aggregatedRatingLabel').text(Number(result.aggregatedRating).toFixed(1));
                $('#ratingCountLabel').text(result.ratingCount);
                M.toast({html: instance.i18next.t('toasts.data_updated')});
            } catch (error) {
                M.toast({html: instance.i18next.t('toasts.error')});
                instance.log.error('could not add article rating: ',error);
            }
        }.bind(this);

        instance.ratingControl.attachTo('quackRatingControl');
    }

    /**
     * Actually adds the review controls.
     */
    async _addReviewCapabilites() {
        const instance = this;
        instance.utils.linkifyText();

        instance.log.debug('Initialising review controls for article quack page!');
        $('#articleTitle').append('<div id="titleReviewControls"></div>');
        $('#articleSummary').append('<div id="summaryReviewControls"></div>');
        let articleId = $('#quackSingleChatContainer').attr('data-article-id');

        // helper function to update question properties
        function updateArticle(labelId, property, value) {
            let params = { "id": articleId};
            params[property] = value;

            return instance.chatService.updateArticle(params)
                .then(function () {
                    M.toast({html: instance.i18next.t('toasts.data_updated')});
                    return true;
                }, function(reason) {
                    M.toast({html: instance.i18next.t('toasts.error')});
                    instance.log.error('update failed because ', reason);
                    return false;
                });
        }

        const titleEditField = new monstecLib.InPlaceEditField($('#articleTitleLabel'));

        // define actions buttons for the article title
        let titleButtonConfig = [
            {
                icon: 'edit',
                showLoader: true,
                func: function() {
                    titleEditField.onCommit = async function() {
                        let title = titleEditField.getValue();
                        return updateArticle('titleTopicLabel', 'title', title);
                    };

                    titleEditField.onGetContent = async function() {
                        let rawArticle = await instance.chatService.getArticleForEditing(articleId);
                        return rawArticle.title;
                    };

                    titleEditField.onBeforeRender = async function() {
                        let article = await instance.chatService.getPublicArticleData(articleId);
                        return article.title;
                    };

                    return titleEditField.replaceSource();
                }
            }
        ];

        let titleActionButtons = new monstecLib.ActionButtons(titleButtonConfig, 'left');
        titleActionButtons.attachTo('titleReviewControls');

        const summaryEditField = new monstecLib.InPlaceEditField($('#articleSummaryLabel'));

        // define actions buttons for the article summary
        let summaryButtonConfig = [
            {
                icon: 'edit',
                showLoader: true,
                func: function() {
                    summaryEditField.onGetContent = async function() {
                        let rawArticle = await instance.chatService.getArticleForEditing(articleId);
                        return rawArticle.summary;
                    };

                    summaryEditField.onBeforeRender = async function() {
                        let article = await instance.chatService.getPublicArticleData(articleId);
                        return article.summary;
                    };

                    summaryEditField.onCommit = async function() {
                        let summary = summaryEditField.getValue();
                        return updateArticle('articleSummaryLabel', 'summary', summary);
                    };

                    return summaryEditField.replaceSource();
                }
            }
        ];

        let summaryActionButtons = new monstecLib.ActionButtons(summaryButtonConfig, 'left');
        summaryActionButtons.attachTo('summaryReviewControls');

        // a function for sending an update for an article block to the platform
        async function updateArticleBlock(text, blockId) {
            return instance.chatService.updateArticleBlock(blockId, text)
                .then(function () {
                    M.toast({html: instance.i18next.t('toasts.data_updated')});
                    setTimeout(() => {
                        instance.utils.initMaterializeInContentBlock();
                    }, 1000);
                    return true;
                }, function(reason) {
                    M.toast({html: instance.i18next.t('toasts.error')});
                    instance.log.error('update failed because ', reason);
                    return false;
                });
        }

        $('.js-block-action-buttons').each(function(index, element) {

            if ($(element).children().length !== 0) {
                return false;
            }

            let blockId = $(element).attr('data-block-id');

            const isoLngCode = $('html').attr('lang');
            const blockEditField = new monstecLib.InPlaceEditField($('#blockTextLabel' + blockId), {"add": true, "lng": isoLngCode, "postId": "article"+articleId}, 'window');

            let blockButtonConfig = [
                {
                    icon: 'delete_forever',
                    func: function() {
                        try {
                            let blockElement = $('.js-block-bubble[data-block-id=' + blockId +']').parent('.article-block');

                            var headline = instance.i18next.t('articlesection.delete_block');
                            var text = instance.i18next.t('articlesection.delete_block_confirmation');
                            var optionOne = instance.i18next.t('text.yes_delete');
                            var optionTwo = instance.i18next.t('general.no');

                            //creates a suitable modal
                            instance.utils.createModal($('body'), headline, text, optionOne, optionTwo, 'deleteArticleBlockModal');

                            // call delete function if delete gets confirmed
                            $('#deleteArticleBlockModal .modal-close.option-one').on('click', function() {
                                instance.chatService.deleteArticleBlock(blockId).then(function() {
                                    blockElement.remove();
                                });
                                $('#deleteArticleBlockModal').remove();
                            });

                            // reset all conditions if delete gets declined
                            $('#deleteArticleBlockModal .modal-close.option-two').on('click', function() {
                                $('#deleteArticleBlockModal').remove();
                            });

                        } catch (errorResponse) {
                            instance.log.debug('Deleting article block failed!', errorResponse);
                            if (errorResponse.status == 400) {
                                M.toast({html: instance.i18next.t('toasts.data_invalid')});
                            } else {
                                M.toast({html: instance.i18next.t('toasts.error')});
                            }
                        }
                    }
                },
                {
                    icon: 'edit',
                    showLoader: true,
                    func: function() {
                        blockEditField.onCommit = async function() {
                            let text = blockEditField.getValue();
                            return updateArticleBlock(text, blockId);
                        };

                        blockEditField.onBeforeRender = function(value) {
                            return value.linkify('*');
                        };

                        blockEditField.onBeforeRender = async function() {
                            let block = await instance.chatService.getPublicArticleBlockData(blockId);
                            return block.text.linkify('*');
                        };

                        blockEditField.onGetContent = async function() {
                            let block = await instance.chatService.getArticleBlockForEditing(blockId);
                            return block.text;
                        };

                        return blockEditField.replaceSource();
                    }
                }
            ];

            let blockActionButtons = new monstecLib.ActionButtons(blockButtonConfig, 'left');
            blockActionButtons.attachTo('blockReviewControls' + blockId);
            $(element).removeClass('hide');
        });

        $(document).trigger("loader:off");
    }

    _showInternalAds() {

        const instance = this;

        let targetElem = $('.article-block').first();
        let tagsArr = $('.quack-tag-block').data('tags');
        
        instance.advertisement.initialise(tagsArr, targetElem);
    }
}
