/**
 * Provides a handler that will be called whenever a textarea with multimedia support is opened.
 */
export default class WysiwygControl {
    /**
     * Creates a new Wysiwyg Bar
     */
    constructor(externalUtils) {
        const instance = this;
        instance.utils = externalUtils;
        instance.i18next = monstecLib.i18next;
        instance.cookie = new monstecLib.Cookie();
        instance.authenticator = new monstecLib.Authenticator(instance.cookie);
        instance.chatService = new monstecLib.ChatServiceAccess(
            instance.authenticator
        );

        let context = monstecLib.produckContext;
        context.setBean("i18next", this.i18next);
        context.setBean("utils", this.utils);
        context.setBean("cookie", this.cookie);
        context.setBean("authenticator", this.authenticator);
        context.setBean("chatService", this.chatService);

        monstecLib.produckContext.initialiseRegisteredBeans();

        instance.redoUndoCache = [];
        instance.currentStep = 0;
    }

    createWysiwygBarHTML(data) {
        var instance = this;

        let buttons = [
            {
                name: "aigenerator",
                title: "AI-Generator",
                icon: "smart_toy",
                specs: data,
            },
            {
                name: "bold",
                title: "Fett <strong> Strg+B",
                icon: "format_bold",
                tag: "<strong></strong>",
                wrap: true,
            },
            {
                name: "italic",
                title: "Kursiv <em> Strg+I",
                icon: "format_italic",
                tag: "<em></em>",
                wrap: true,
            },
            {
                name: "unterline",
                title: "Unterstreichen <u>",
                icon: "format_underline",
                tag: "<u></u>",
                wrap: true,
            },
            {
                name: "headline-h2",
                title: "Überschrift <h2>",
                icon: "looks_two",
                tag: "<h2></h2>\r\n",
                wrap: true,
            },
            {
                name: "headline-h3",
                title: "Überschrift <h3>",
                icon: "looks_3",
                tag: "<h3></h3>\r\n",
                wrap: true,
            },
            {
                name: "link",
                title: "Link <a> Strg+K",
                icon: "link",
                tag: '[url="Link",name="Linkname",titel="Linktitel"]',
            },
            {
                name: "quote",
                title: "Zitat <blockquote>",
                icon: "format_quote",
                tag: "\r\n<blockquote><p></p></blockquote>\r\n",
                wrap: true,
            },
            {
                name: "hor-line",
                title: "Horizontale Linie <hr>",
                icon: "remove",
                tag: "\r\n<hr>\r\n",
            },
            {
                name: "line-break",
                title: "Zeilenumbruch <br>",
                icon: "wrap_text",
                tag: "\r\n<br>\r\n",
            },
            {
                name: "image",
                title: "Bild <img> Strg+G",
                icon: "image",
                tag: '\r\n<figure>\r\n\t<img src="Link eintragen" alt="Bildbeschreibung">\r\n\t<figcaption>Bilduntertitel</figcaption>\r\n</figure>\r\n',
            },
            {
                name: "olist",
                title: "Nummerierung <ol>",
                icon: "format_list_numbered",
                tag: "\r\n<ol>\r\n\t<li></li>\r\n\t<li></li>\r\n</ol>\r\n",
            },
            {
                name: "ulist",
                title: "Aufzählungszeichen <ul>",
                icon: "format_list_bulleted",
                tag: "\r\n<ul>\r\n\t<li></li>\r\n\t<li></li>\r\n</ul>\r\n",
            },
            {
                name: "table",
                title: "Tabelle <table>",
                icon: "view_list",
                tag: "\r\n<table>\r\n\t<thead>\r\n\t\t<tr><th></th><th></th></tr>\r\n\t</thead>\r\n\t<tbody>\r\n\t\t<tr><td></td><td></td></tr>\r\n\t</tbody>\r\n</table>\r\n",
            },
            {
                name: "undo",
                title: "Rückgänging Strg+Z",
                icon: "undo",
                tag: "",
            },
            { name: "redo", title: "Wiederholen Strg+Y", icon: "redo" },
            { name: "switchView", title: "Ansicht wechseln </>", icon: "code" },
            { name: "spacer", title: "", icon: "" },
        ];

        // text embedded in template literals
        var row = `<ul class="wswg-button-row">${buttons
            .map(
                (button) =>
                    `<li class="wswg-button wswg-${button.name}-button" title="${button.title}"><i class="material-icons">${button.icon}</i></li>`
            )
            .join("")}</ul>`;
        var imgUpldHtml = instance.imgUpldContHTML();
        var linkAddHTML = instance.linkContHTML();
        var wrapper = `<div class="wswg-button-bar">${row}${imgUpldHtml}${linkAddHTML}</div>`;

        return { wrapper, buttons };
    }

    handleShortCuts(textField) {
        let instance = this,
            ctrlDown = false,
            ctrlKey = 17,
            kKey = 75,
            gKey = 71,
            returnKey = 13,
            pkey = 80,
            bkey = 66,
            ikey = 73,
            insertText = function (link) {
                document.execCommand("insertText", false, link);
            },
            adjustTextfield = function (newCursorLocation) {
                $(textField).prop("selectionEnd", newCursorLocation);
                instance.utils.adjustTextarea($(textField), 1500);
            };

        textField.onkeydown = async function (e) {
            const keyCode = window.event ? e.which : e.keyCode;

            if (keyCode == 17 || keyCode == 91) {
                ctrlDown = true;
            }

            async function getClipboardContents() {
                let browser = instance.utils.detectBrowser(),
                    content = null;

                if (browser === "firefox") {
                    alert("Diese Funktion ist in Firefox nicht verfügbar.");
                    return;
                } else {
                    content = await navigator.clipboard.readText();
                }
                return content;
            }

            //bei gespeicherten Blöcken wird handleShortCuts wohl nicht aufgerufen

            //Ctrl + k for hyperlinks
            if (ctrlDown && keyCode == kKey) {
                e.preventDefault();

                const start = $(textField)[0].selectionStart;
                const end = $(textField)[0].selectionEnd;
                const url = await getClipboardContents();
                const selText = instance.utils.getSelectionText($(textField));
                const urlFormatted = `[url="${url}",name="${selText}",title=""]`;
                const cursorLocation = instance.utils.returnCursorPosition(
                    $(textField)
                );

                let newCursorLocation = start + urlFormatted.length;

                insertText(urlFormatted);

                adjustTextfield(newCursorLocation);

                ctrlDown = false;

                return false;
            }

            //Ctrl + p to wrap in paragraph
            if (ctrlDown && keyCode == pkey) {
                e.preventDefault();

                const start = $(textField)[0].selectionStart;
                const end = $(textField)[0].selectionEnd;
                const selText = instance.utils.getSelectionText($(textField));
                const textFormatted = `<p>${selText}</p>`;
                const cursorLocation = instance.utils.returnCursorPosition(
                    $(textField)
                );

                let newCursorLocation = start + textFormatted.length;

                insertText(textFormatted);

                adjustTextfield(newCursorLocation);

                ctrlDown = false;

                return false;
            }

            function textWrapper(key, patternStart, patternEnd) {
                if (ctrlDown && keyCode == key) {
                    e.preventDefault();

                    const start = $(textField)[0].selectionStart;
                    const end = $(textField)[0].selectionEnd;
                    const selText = instance.utils.getSelectionText(
                        $(textField)
                    );
                    const textFormatted = `${patternStart}${selText}${patternEnd}`;
                    const cursorLocation = instance.utils.returnCursorPosition(
                        $(textField)
                    );

                    let newCursorLocation = start + textFormatted.length;

                    insertText(textFormatted);

                    adjustTextfield(newCursorLocation);

                    ctrlDown = false;

                    return false;
                }
            }

            //Ctrl + p to wrap in paragraph
            textWrapper(pkey, "<p>", "</p>");
            //Ctrl + b to wrap in strong - bold
            textWrapper(bkey, "<strong>", "</strong>");
            //Ctrl + i to wrap in em - italic
            textWrapper(ikey, "<em>", "</em>");

            //Ctrl + g for images
            if (ctrlDown && keyCode == gKey) {
                e.preventDefault();

                const imgLink = await getClipboardContents(),
                    headline = `Bild einfügen`,
                    optionOne = instance.i18next.t("general.yes"),
                    optionTwo = instance.i18next.t("general.cancel"),
                    patterns = instance.utils.linkifyText();

                let imgLinkFormatted,
                    cursorLocation = instance.utils.returnCursorPosition(
                        $(textField)
                    );

                function initModal(textBody, modalId, imgLink) {
                    instance.utils.createModal(
                        $("body"),
                        headline,
                        textBody,
                        optionOne,
                        optionTwo,
                        modalId
                    );

                    $("#" + modalId)
                        .find(".modal-close.option-one")
                        .focus()
                        .on("click ", function () {
                            insertText(imgLinkFormatted);
                        });

                    $(document).on("keydown.modalHandler", function (e) {
                        const keyCode = window.event ? e.which : e.keyCode;

                        if (
                            $("#" + modalId).length &&
                            $("#" + modalId)
                                .find(".modal-close.option-one")
                                .is(":focus") &&
                            keyCode == returnKey
                        ) {
                            insertText(imgLink);
                            $("#" + modalId).modal("close");
                            $(document).off("keydown.modalHandler");

                            let newCursorLocation =
                                cursorLocation.pos + imgLinkFormatted.length;
                            adjustTextfield(newCursorLocation);
                        } else if (
                            $("#" + modalId).length &&
                            $("#" + modalId)
                                .find(".modal-close.option-two")
                                .is(":focus") &&
                            keyCode == returnKey
                        ) {
                            $("#" + modalId).modal("close");
                        }
                    });
                }

                if (imgLink.match(patterns.urlPattern)) {
                    let textBody = `Möchtest du das Bild mit folgenderm Link einfügen?<br><br>${imgLink}`;
                    imgLinkFormatted = `\r\n<figure>\r\n\t<img src="${imgLink}" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;

                    initModal(textBody, "imgLinkAsURLModal", imgLinkFormatted);
                } else if (imgLink.match(patterns.pseudoUrlPattern)) {
                    let textBody = `Bitte gib deinen Bild Link beginnend mit https ein:<br><br>"${imgLink.substr(
                        0,
                        8
                    )}..."`;
                    imgLinkFormatted = `\r\n<figure>\r\n\t<img src="https://${imgLink}" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;

                    initModal(
                        textBody,
                        "imgLinkAsPseudoURLModal",
                        imgLinkFormatted
                    );
                } else {
                    imgLinkFormatted = `\r\n<figure>\r\n\t<img src="LINK EINFÜGEN" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;
                    insertText(imgLinkFormatted);

                    let newCursorLocation =
                        cursorLocation.pos + imgLinkFormatted.length;
                    adjustTextfield(newCursorLocation);
                }

                ctrlDown = false;
                return false;
            }
        };

        textField.onkeyup = function (e) {
            const keyCode = window.event ? e.which : e.keyCode;

            if (keyCode == 17 || keyCode == 91) {
                ctrlDown = false;
            }
        };
    }

    //set new text
    initPasteTextToCursorPosition(wysiwygBar, buttonsDefinition, textField) {
        var instance = this;

        var actionsInCache = instance.redoUndoCache;
        //actionsInCache.length = 0;        

        buttonsDefinition.map((button) => {
            var buttonElem = wysiwygBar.find(`.wswg-${button.name}-button`);

            buttonElem.off().on("click", () => {
                if (button.name === "undo") {
                    if (instance.currentStep > 0) {
                        $(textField).val(
                            actionsInCache[instance.currentStep - 1].initialText
                        );

                        $(textField)
                            .prop(
                                "selectionEnd",
                                actionsInCache[instance.currentStep - 1]
                                    .initialCursorLocation
                            )
                            .focus();

                            instance.currentStep--;
                    }
                } else if (button.name === "redo") {
                    if (instance.currentStep < actionsInCache.length) {
                        $(textField).val(actionsInCache[instance.currentStep].newText);

                        $(textField)
                            .prop(
                                "selectionEnd",
                                actionsInCache[instance.currentStep].newCursorLocation
                            )
                            .focus();

                            instance.currentStep++;
                    } else {
                        return;
                    }
                } else if (button.name === "aigenerator") {
                    let language = button.specs.iso ? button.specs.iso : button.specs.htmlEle ? button.specs.htmlEle.val() : "en-US";
                    let postId = button.specs.postId ? button.specs.postId : null; 
                    instance.initAIGenerator($(textField), language, postId);
                } else if (button.name === "image") {
                    var imgContainer = wysiwygBar.find(".img-upload-container");

                    imgContainer.toggleClass("editor-active");                    
                    
                    if (!imgContainer.hasClass("active")) imgContainer.find(".js-url-input-container").removeClass("active");

                    instance.initImageUpload(imgContainer, false, $(textField));
                    //TODO allow adding a title and alt text to image via input fields for SEO
                } else if (button.name === "link") {
                    var urlContainer = wysiwygBar.find("#add-url-container");

                    urlContainer.toggleClass("editor-active");
                    instance._buildLink(urlContainer, $(textField));
                    instance._closeInputContainerListener(urlContainer);
                } else if (button.name === "switchView") {
                    let content = $(textField).val();
                    let wrappedParagraphs =
                        instance.utils.wrapParagraphs(content);

                    //TODO: Ist content von fremder Person, muss dieser vom Server abgerufen werden, da sonst script injection mgl, Problem allerdings, dass content ja noch nicht gespeichert

                    let simplScriptRegex =
                        /\.*(script|onerror|href|javascript)\b/gim;

                    if (wrappedParagraphs.match(simplScriptRegex)) {
                        alert("potential script detected");
                        return false;
                    } else {
                        $(textField).toggleClass("hide");
                        $(textField)
                            .siblings(".js-visual-input-area")
                            .html(wrappedParagraphs)
                            .toggleClass("hide");
                    }
                } else {
                    let selText = instance.utils.getSelectionText($(textField)),
                        buttonTag = button.tag;

                    if (selText && button.wrap === true) {
                        let tag = button.tag,
                            openTag = buttonTag.match(/<[^\/ ]+>/gi),
                            closeTag = buttonTag.match(/<[\/].*>/gi);

                        buttonTag = openTag + selText + closeTag;
                    }

                    instance._addTagsToTextarea($(textField), buttonTag);
                    instance.currentStep++;
                }
            });
        });
    }

    _addTagsToTextarea(textField, innerHtml) {
        var instance = this,
            cursorLocation = instance.utils.returnCursorPosition(textField),
            initialText = textField.val(),
            newText =
                initialText.substr(0, cursorLocation.pos) +
                innerHtml +
                initialText.substr(
                    cursorLocation.pos + cursorLocation.selLength
                ),
            firstLibr = innerHtml.split(/^\r\n/).length - 1,
            innerLibr =
                innerHtml.split(/\r\n.(?!:\r\n)/).length -
                1 -
                (innerHtml.split(/^\r\n/).length - 1),
            innerTabs = innerHtml.split(/\t/).length - 1, // count all tabs; calc -1 to exclude last split fragment
            plainInnerHTML = innerHtml.trim().replace(/\r|\n|\t/g, ""); // remove all whitespaces

        let newCursorLocation =
            cursorLocation.pos +
            plainInnerHTML.length +
            firstLibr +
            innerLibr +
            innerTabs;

        textField.val(newText);
        textField.prop("selectionEnd", newCursorLocation);
        instance.utils.adjustTextarea(textField, 1500);
        textField.focus();

        instance.redoUndoCache.push({
            initialText: initialText,
            newText: newText,
            initialCursorLocation: cursorLocation.pos,
            newCursorLocation: newCursorLocation,
        });
    }

    imgUpldContHTML() {
        var uploadInterFaceHtml =
            '<div class="img-upload-container">' +
            '  <input class="file-upld-path ps-absolute o0" type="file" name="file" accept="image/*" />' +
            '  <div class="img-upload-area drag-trgt">' +
            '    <div class="img-upload-info fs-14 darkco">' +
            "      Nutze folgende Optionen, um ein Bild einzufügen: Ziehe deine Bild-Datei in diesen Bereich, wähle eine" +
            '      <label for="file-upld-trigger" class="fs-14">' +
            '        <a class="browse-file prdk-link bold">Datei</a>' +
            "      </label> aus oder füge die " +
            '      <a class="add-url prdk-link bold">URL des Bildes</a> ein.<span class="info-span"> (Max. 4 MB)<span>' +
            "    </div>" +
            '    <div class="js-url-input-container input-one-liner-box">' +
            '      <div class="ps-relative input-field">' +
            '        <input class="img-upload-url-input placeholder validate" placeholder="Füge einen Link ein" type="url">' +
            "      </div>" +
            '      <a class="js-cancel-url cancel-act prdk-link fs-14">Abbrechen</a>' +
            "    </div>" +
            '    <div class="js-primary-img-checkbox-container hide">' +
            "      <p>" +
            "          <label>" +
            '              <input class="primary-img-checkbox filled-in" type="checkbox" name="checkbox" tabindex="4">' +
            '              <span class="fs-14" tabindex="-1">Dieses Bild als Hauptbild des Artikels verwenden</span>' +
            "          </label>" +
            "      </p>" +
            "    </div>" +
            "  </div>" +
            '  <div class="attached-row">' +
            '      <div class="img-upload-confirm-area button-row fs-14">' +
            '        <a class="file-upld-button prdk-btn btn waves-effect waves-light disabled fs-14">Bild hinzufügen</a>' +
            '        <a class="js-cancel-add cancel-act prdk-link">Abbrechen</a>' +
            "      </div>" +
            '      <div class="flex-box license-info">' +
            '        <span class="flex-box">Powered by imgbb.&nbsp;</span>' +
            '        <span class="flex-box">User contributions licensed under&nbsp;<a href="https://creativecommons.org/licenses/by-sa/4.0/" class="prdk-link">cc by-sa (content policy)</a>.</span>' +
            "      </div>" +
            "  </div>" +
            "</div>";

        return uploadInterFaceHtml;
    }

    linkContHTML() {
        var insertUrlInterfaceHtml =
            '<div id="add-url-container" class="add-input-container">' +
            '  <div id="add-url-area" class="add-input-area">' +
            '    <div class="js-url-input-container active">' +
            '      <div class="input-field ps-relative flex-wrap">' +
            '        <input class="add-url-input placeholder validate" name="href" placeholder="Füge einen Link ein" type="url" value="https://" tabindex="1">' +
            '        <input class="add-url-input placeholder validate" name="name" placeholder="Füge den Namen des Links ein" type="text" tabindex="2">' +
            '        <input class="add-url-input placeholder validate" name="title" placeholder="Füge eine Beschreibung des Links ein (optional)" type="text" tabindex="3">' +
            "      </div>" +
            "    </div>" +
            "  </div>" +
            '  <div class="attached-row">' +
            '      <div class="add-confirm-area button-row fs-14">' +
            '        <a class="add-button prdk-btn btn waves-effect waves-light fs-14">Link hinzufügen</a>' +
            '        <a class="js-cancel-add cancel-act prdk-link">Abbrechen</a>' +
            "      </div>" +
            "  </div>" +
            "</div>";

        return insertUrlInterfaceHtml;
    }

    initImageUpload(imgContainer, inputField, textfield) {
        var instance = this,
            inputElement = imgContainer.find(".file-upld-path"),
            fileUploadBtn = imgContainer.find(".file-upld-button"),
            dragTarget = imgContainer.find(".drag-trgt");

        function initDragStyle() {
            dragTarget.off();

            dragTarget.on("dragover", function (event) {
                event.preventDefault();
                event.stopPropagation();
                $(this).addClass("dragging");
            });

            dragTarget.on("dragleave", function (event) {
                event.preventDefault();
                event.stopPropagation();
                $(this).removeClass("dragging");
            });

            dragTarget.on("drop", function (event) {
                event.preventDefault();
                event.stopPropagation();

                $(this).removeClass("dragging");
                const file = event.originalEvent.dataTransfer.files[0];

                if (validateImage(file)) {
                    createPreviewFromUpld(file);
                    activateUpldBtn(file);
                }
            });
        }

        function initUploadviaBrowse() {
            const primImgCheckboxContainer = imgContainer.find("js-primary-img-checkbox-container");
            imgContainer
                .find(".browse-file")
                .off()
                .click(() => {            
                    instance._resetImgPreview(imgContainer);
                    instance._resetImgCheckbox(imgContainer);
                    const urlImgInput = imgContainer.find(".js-url-input-container");

                    if (urlImgInput.hasClass("active")) urlImgInput.removeClass("active");

                    inputElement.click();
                });
                
                
                inputElement.off().on("change", () => {
                    const file = inputElement.get(0).files[0];
                    if (validateImage(file)) {
                        createPreviewFromUpld(file);
                        if (primImgCheckboxContainer.hasClass("hide")) primImgCheckboxContainer.removeClass("hide");
                        activateUpldBtn(file);
                }
            });
        }

        function initUploadviaURL() {

            imgContainer
                .find(".add-url")
                .off()
                .on("click", () => {
                    const urlInputContainer = imgContainer.find(".js-url-input-container");
                    const primaryImgCheckboxContainer = imgContainer.find(".js-primary-img-checkbox-container");                    
                    urlInputContainer.toggleClass("active");
            
                    // Show or hide the primary image checkbox container based on the "active" class
                    if (urlInputContainer.hasClass("active")) {
                        primaryImgCheckboxContainer.removeClass("hide");
                    } else {
                        primaryImgCheckboxContainer.addClass("hide");
                    }
                });

            imgContainer
                .find(".js-cancel-url")
                .off()
                .on("click", () => {
                    imgContainer
                        .find(".js-url-input-container")
                        .removeClass("active");
                        imgContainer.find($(".file-upld-button")).addClass("disabled");
                        instance._resetImgPreview(imgContainer);
                        instance._resetImgCheckbox(imgContainer);
                });

            imgContainer.find(".js-url-input-container").on("input", () => {
                var imgInput = imgContainer.find(".img-upload-url-input");
                var imgUrl = imgInput.val().trim();
                var imgDatafromUrl = { data: { image: { url: imgUrl } } };
                createPreviewFromURL(imgContainer, imgUrl);
                activateUpldBtn(imgDatafromUrl, "url");
            });
        }

        function createPreviewFromURL(imgContainer, imgUrl) {
            const imagePreviewBox = imgContainer.find(".js-image-upld-preview");
            const primImgCheckbox = imgContainer.find(".js-primary-img-checkbox-container");

            imagePreviewBox.length
                ? imagePreviewBox.attr("src", imgUrl)
                : setNewImage(imgUrl);

            function setNewImage(url) {
                var img = new Image();

                primImgCheckbox
                    .removeClass("hide")
                    .before(img);

                img.src = url;
                img.className = "js-image-upld-preview";
            }
        }

        function activateUpldBtn(file, type) {
            fileUploadBtn.removeClass("disabled");

            fileUploadBtn.off().on("click", () => {
                if (type === "url") {
                    instance._showPictureinContentArea(
                        JSON.stringify(file),
                        imgContainer,
                        inputField,
                        textfield
                    );
                    fileUploadBtn.addClass("disabled");
                    imgContainer.find(".img-upload-url-input").val("");
                    instance._closeInputContainer(imgContainer);
                } else {
                    instance.utils.addButtonLoader(fileUploadBtn);
                    inputElement.val("");
                    instance.imageUploadImgbb(
                        file,
                        imgContainer,
                        inputField,
                        textfield
                    );
                }
            });
        }

        function createPreviewFromUpld(arrayBufferWithIMG) {
            var prevImg = imgContainer.find(".js-image-upld-preview"),
                primImgCheckbox = imgContainer.find($(".js-primary-img-checkbox-container")),
                blob = new Blob([arrayBufferWithIMG], { type: "image/*" }),
                url = URL.createObjectURL(blob);

            prevImg.length ? prevImg.attr("src", url) : setNewImage();

            function setNewImage() {
                var img = new Image();

                img.onload = function () {
                    URL.revokeObjectURL(this.src); // clean-up memory
                    primImgCheckbox
                        .removeClass("hide")
                        .before(this); // add image to DOM
                };

                img.src = url;
                img.className = "js-image-upld-preview";
            }
        }

        function handleFiles(files) {
            for (var i = 0, len = files.length; i < len; i++) {
                if (validateImage(files[i])) previewAnduploadImage(files[i]);
            }
        }

        function validateImage(image) {
            // check the type
            var validTypes = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
            ];
            if (validTypes.indexOf(image.type) === -1) {
                instance.utils.createSimpleAlertModal(
                    "Invalid File Type. Use jpeg, jpg, png or gif."
                );
                return false;
            }

            // check the size
            var maxSizeInBytes = 4e6; // 4MB
            if (image.size > maxSizeInBytes) {
                instance.utils.createSimpleAlertModal(
                    "File too large. The max. file size is 4MB"
                );
                return false;
            }

            return true;
        }

        instance._closeInputContainerListener(imgContainer);

        initDragStyle();
        initUploadviaBrowse();
        initUploadviaURL();
    }

    _resetImgPreview(imgContainer) {
        let imagePreviewBox = imgContainer.find(".js-image-upld-preview");
        if (imagePreviewBox.length) imagePreviewBox.attr("src", "");
        imgContainer.find(".img-upload-url-input").val('');
    }

    _resetImgCheckbox(imgContainer) {
        imgContainer.find(".js-primary-img-checkbox-container")
            .addClass("hide")
            .find(".primary-img-checkbox")
            .prop("checked", false);
    }

    imageUploadImgbb(imgObj, imgContainer, inputField, textfield) {
        var instance = this,
            fileUploadBtn = imgContainer.find(".file-upld-button"),
            form = new FormData();

        form.append("image", imgObj);

        //var settings = {
        //  "url": "https://api.imgur.com/3/image",
        //  "method": "POST",
        //  "timeout": 0,
        //  "headers": {
        //    "Authorization": "Bearer 315fb69051dbf7060d19e31192cc06cd9d3b2bb2",
        //  },
        //  "processData": false,
        //  "mimeType": "multipart/form-data",
        //  "contentType": false,
        //  "data": form
        //};

        var settings = {
            url: "https://api.imgbb.com/1/upload?key=9653c85c61f072f5effa773e572125c2",
            method: "POST",
            timeout: 0,
            processData: false,
            mimeType: "multipart/form-data",
            contentType: false,
            data: form,
        };

        $.ajax(settings)
            .done((response) => {
                //console.log('Upload successful: ', response);

                instance._showPictureinContentArea(
                    response,
                    imgContainer,
                    inputField,
                    textfield
                );

                instance.utils.removeButtonLoader(fileUploadBtn);
                fileUploadBtn.addClass("disabled");
                imgContainer.find(".file-upld-path").val("");
                instance._closeInputContainer(imgContainer);
            })
            .fail((error) => {
                console.log("Could not upload file: ", error);
                var textBody =
                    "Leider ist beim Bildupload etwas schief gelaufen. Bitte versuchen Sie es noch einmal.";
                instance.utils.removeButtonLoader(fileUploadBtn);
                fileUploadBtn.addClass("disabled");
                instance.utils.createSimpleAlertModal(textBody);
            });
    }

    _showPictureinContentArea(data, imgContainer, inputField, textField) {
        var instance = this,
            dataParsed = JSON.parse(data),
            imgData = data,
            imgLink = dataParsed.data.image.url;

        if (inputField) {
            $(inputField).val(imgLink);
        } else {
            var primaryImgCheck = imgContainer.find('.primary-img-checkbox').is(':checked') ? 'id="primaryImage" ' : '';
            var divImgLink = `\r\n<figure>\r\n\t<img ${primaryImgCheck}src="${imgLink}" alt="BILDBESCHREIBUNG">\r\n\t<figcaption>BILDUNTERTITEL</figcaption>\r\n</figure>\r\n`;
            //var textField = imgContainer.siblings('textarea.wswg-active');

            instance._addTagsToTextarea(textField, divImgLink);
        }
    }

    _buildLink(urlContainer, textField) {
        var instance = this,
            $submitLink = urlContainer.find(".add-button"),
            linkData = {},
            selText = instance.utils.getSelectionText(textField);

        if (selText) {
            var $inputFieldLinkName = urlContainer.find(
                '.add-url-input[name="name"]'
            );
            $inputFieldLinkName.val(selText);
        }

        $submitLink.off().on("click", function () {
            var $inputFields = urlContainer.find(".add-url-input");

            $inputFields.each((i, ele) => {
                var value = $(ele).val();
                var key = $(ele).attr("name");

                linkData[key] = value;

                $(ele).val("");
            });

            var linkObj =
                '[url="' +
                linkData["href"] +
                '",name="' +
                linkData["name"] +
                '",title="' +
                linkData["title"] +
                '"]';
            //var textField = urlContainer.siblings('textarea.wswg-active');
            instance._addTagsToTextarea(textField, linkObj);
            instance._closeInputContainer(urlContainer);
        });
    }

    _closeInputContainerListener(container) {
        const instance = this;
        container
            .find(".js-cancel-add")
            .off()            
            .click(() => container.removeClass("editor-active"));

        container.find($(".file-upld-button")).addClass("disabled");
            instance._resetImgPreview(container);
            instance._resetImgCheckbox(container);
    }

    _closeInputContainer(container) {
        const instance = this;
        container.removeClass("editor-active");
        instance._resetImgPreview(container);
        instance._resetImgCheckbox(container);    
    }
    // detect link pattern on load
    // build link with a href title
    // allow alt attributes for images

    // TODO i18n Translation
    // TODO Link for License Agreement see https://stackoverflow.com/help/licensing

    async initAIGenerator($textField, targetLanguage, postId) {
        const instance = this;
        const text = $textField.val();
        instance.AIGenerator = new monstecLib.AIGenerator();


        const productName = "siehe oben"; // TODO Produkt muss als Feld in Artikel gespeichert werden können // Hier könnte man später eine Verbindung zur Produktdatenbank herstellen, bei der Tester entweder ein Produkt aus der Datenbank auswählen können, oder, wenn noch nicht eingetragen, ein Produkt in die Datenbank eintragen können (z.B. inkl Spezifikationen und Bildern). Artikel ohne Produkt werden ggf. nicht zugelassen.
        const articleType = "undefined"; // TODO For now Dummy; Background: "Type of Article - News/Test/Vergleich/Ratgeber"; //prompt is unterschiedlich für Testbericht/Vergleich/Ratgeber/News oder offenes Thema
        const productDbData = ""; // TODO For now Dummy; Background: Als quellen wäre ASIN möglich / interaktion mit PAAPI oder manuelle Eingabe des Autors // verhindern, dass Produkte doppelt eingetragen werden, d.h. entweder ist Produkt vorhanden und man kann es nur erweitern oder man kann ein neues Anlegen
        const matchHealdline = text.match(/<h2>(.*?)<\/h2>/); 
        const capitelHeadline = matchHealdline ? matchHealdline[1] : '';

        const mainPrompt = `Du bist Blogger und Experte für das Produkt - ${productName}. Für deine Leser möchtest du einen ${articleType}Artikel in der Sprache ${targetLanguage} verfassen. Schreibe nachfolgend ein Kapitel "${capitelHeadline}" unter Verwendung der nachfolgenden Infos.`;

        const content =
            mainPrompt
            + text;
            //+ "<br/> Weitere Produktdaten: "
            //+ productDbData;

        //const content1 = "Answser the following question " + text + ". Use language " + targetLanguage; //TODO Test question Remove
        
        instance.utils.addButtonLoader($textField.siblings(".wswg-button-bar").find(".wswg-aigenerator-button"));

        let airesponse = await instance.AIGenerator.callChatGPTAPI(content, postId);   
        if (airesponse !== null) {            
            $textField.val(airesponse);
            instance.utils.adjustTextarea($textField, 1500, 72);

            instance.currentStep++;
            
            instance.redoUndoCache.push({
                    initialText: text,
                    newText: airesponse,
                    initialCursorLocation: 0,
                    newCursorLocation: 0,
            });
        }
       
        //instance.utils.adjustScrollBugInTextarea(airesponse, 'window');
        instance.utils.removeButtonLoader($textField.siblings(".wswg-button-bar").find(".wswg-aigenerator-button"));   
        $textField.focus();
    }
}
